<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { configurationDataApi } from '@js/api/configurationDataApi'
import AppSidebar from '@js/components/AppSidebar.vue'
import Translator from '@js/translator'
import { usePageStore } from '@js/stores/page'
import type { ConfigurationDataSidebarMenu } from '@js/api/configurationDataApi'

const pageStore = usePageStore()
const isCollapsed = computed(() => pageStore.sidebarCollapsed)
const route = useRoute()
const sidebarMenuRaw = ref<ConfigurationDataSidebarMenu>()

const sidebarMenu = computed(() => {
  return sidebarMenuRaw.value?.children.map((headerMenu) => {
    return {
      ...headerMenu,
      children: headerMenu.children.map((menuItem) => {
        return {
          ...menuItem,
          to: menuItem.extras.vueRoute,
          current: menuItem.extras.vueRoute.name === route.name,
        }
      }),
    }
  })
})

onMounted(async () => {
  const response = await configurationDataApi.fetchAdminAreaSidebarMenu()
  sidebarMenuRaw.value = response.data
})
</script>

<template>
  <AppSidebar>
    <div v-if="!isCollapsed" class="pl-3 pt-3">
      <nav v-if="sidebarMenu">
        <ul v-for="header in sidebarMenu" :key="header.name" class="mt-3 first:mt-0">
          <li>
            <span class="font-bold">{{ Translator.trans(header.label) }}</span>
            <ul v-for="item in header.children" :key="item.name">
              <li class="pl-3 leading-relaxed">
                <router-link
                  v-if="item.to"
                  :to="item.to"
                  :class="{ 'font-bold text-action-darker': item.current }"
                >
                  {{ Translator.trans(item.label) }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </AppSidebar>
</template>
