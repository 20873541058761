import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { Currency } from '@js/model/currency'

const basePath = '/api/currencies'

export function fetchCurrenciesByQuery(query: {
  page?: number
  iso4217code?: string
  name?: string
  'sort[iso4217code]'?: SortingDirection
  'sort[name]'?: SortingDirection
  pagination?: boolean
  itemsPerPage?: number
}) {
  return axios.get<HydraCollectionResponse<Currency>>(basePath, {
    params: { ...query },
  })
}

export function fetchAllCurrencies() {
  return axios.get<HydraCollectionResponse<Currency>>(basePath, {
    params: { pagination: false },
  })
}

export function fetchCurrencyById(id: number) {
  return fetchCurrencyByIri(`${basePath}/${id}`)
}

export function fetchCurrencyByIri(iri: ApiResourceId) {
  return axios.get<Currency>(iri)
}

export function deleteCurrencyById(id: number) {
  return axios.delete<Currency>(`${basePath}/${id}`)
}

export function saveCurrency(currency: Partial<Currency>) {
  if (currency.id === undefined) {
    return axios.post<Currency>(basePath, currency)
  }
  return axios.put<Currency>(`${basePath}/${currency.id}`, currency)
}

export const currencyApi = {
  basePath,
  fetchCurrenciesByQuery,
  fetchAllCurrencies,
  fetchCurrencyById,
  fetchCurrencyByIri,
  deleteCurrencyById,
  saveCurrency,
}
