import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { DataTransferType } from '@js/model/data-transfer'

export const basePath = '/api/data-transfer-types'

export function fetchAllDataTransferTypes() {
  return axios.get<HydraCollectionResponse<DataTransferType>>(basePath)
}

export const dataTransferTypeApi = {
  fetchAllDataTransferTypes,
}
