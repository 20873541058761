import axios from 'axios'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { ExchangeRate } from '@js/model/exchangeRate'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

const basePath = '/api/exchange-rates'

export function fetchExchangeRatesByQuery(query: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  periodId: number
  'sort[inputCurrency.iso4217code]'?: SortingDirection
  'sort[outputCurrency.iso4217code]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[directRate]'?: SortingDirection
  'sort[exchangeRateType]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<ExchangeRate>>(basePath, {
    params: { ...query },
  })
}
export function fetchExchangeRateById(id: number) {
  return fetchExchangeRateByIri(`${basePath}/${id}`)
}
export function fetchExchangeRateByIri(iri: ApiResourceId) {
  return axios.get<ExchangeRate>(iri)
}
export function deleteExchangeRate(id: number) {
  return axios.delete<ExchangeRate>(`${basePath}/${id}`)
}

export function saveExchangeRate(exchangeRate: Partial<ExchangeRate>) {
  if (exchangeRate.id === undefined) {
    return axios.post<ExchangeRate>(basePath, exchangeRate)
  }
  return axios.put<ExchangeRate>(`${basePath}/${exchangeRate.id}`, exchangeRate)
}

export const exchangeRateApi = {
  fetchExchangeRatesByQuery,
  fetchExchangeRateById,
  fetchExchangeRateByIri,
  deleteExchangeRate,
  saveExchangeRate,
}
