import axios from 'axios'
import type { AuditLog } from '@js/model/auditLog'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type {
  AnyUnit,
  LegalUnit,
  OrganisationalGroup,
  PermanentEstablishment,
  Unit,
} from '@js/model/unit'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { PartialDeep } from 'type-fest'

export const unitApiBasePath = '/api/units'

export type UnitCollectionQuery = ApiPaginationQueryParams & {
  'exclude-unassigned'?: boolean
  type?: 'unit' | 'legalunit' | 'permanentestablishment' | 'organisationalgroup'
  'sort[auditor.name]'?: SortingDirection
  'sort[branch.name]'?: SortingDirection
  'sort[contactUser.username]'?: SortingDirection
  'sort[country.nameShort]'?: SortingDirection
  'sort[countryFounded.nameShort]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[currency.iso4217code]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[id]'?: SortingDirection
  'sort[legalForm.name]'?: SortingDirection
  'sort[legalName]'?: SortingDirection
  'sort[name]'?: SortingDirection
  'sort[parentLegalUnit.name]'?: SortingDirection
  'sort[parentLegalUnitIncomeTax.name]'?: SortingDirection
  'sort[parentLegalUnitVat.name]'?: SortingDirection
  'sort[refId]'?: SortingDirection
  'sort[registerNumber]'?: SortingDirection
  'sort[registryPlace]'?: SortingDirection
  'sort[taxAdvisor.name]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  'sort[validFrom]'?: SortingDirection
  'sort[validTo]'?: SortingDirection
  'sort[vatNumber]'?: SortingDirection
  'sort[verified]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchUnitsByQuery(query?: UnitCollectionQuery) {
  return axios.get<HydraCollectionResponse<AnyUnit>>(unitApiBasePath, {
    params: { ...query },
  })
}

export function fetchAllUnits() {
  return fetchUnitsByQuery({ pagination: false, 'sort[name]': 'ASC', 'exclude-unassigned': false })
}
export function fetchUnitAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${unitApiBasePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function updateUnitAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${unitApiBasePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

export function fetchUnitAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

export function updateUnitAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

export function fetchUnitInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

export function fetchUnitById(id: AnyUnit['id']) {
  return axios.get<AnyUnit>(`${unitApiBasePath}/${id}`)
}

export function createUnit(unit: Partial<Unit>) {
  return axios.post<Unit>(unitApiBasePath, unit)
}

export function updateUnit(unit: Partial<Unit>) {
  return axios.put<Unit>(`${unitApiBasePath}/${unit.id}`, unit)
}

export function downloadUnitXls(query: UnitCollectionQuery) {
  return axios.get('/api/units', {
    responseType: 'blob',
    params: {
      ...query,
      pagination: false,
    },
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })
}

export function deleteUnit(unit: AnyUnit) {
  return axios.delete(`${unit['@id']}`)
}

export function fetchUnitLogsByQuery(unitId: AnyUnit['id'], query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuditLog>>(`${unitApiBasePath}/${unitId}/logs`, {
    params: { ...query },
  })
}

export function createLegalUnit(
  legalUnit: Pick<LegalUnit, 'refId' | 'name' | 'currency' | 'country' | 'verified' | 'legalName'>
) {
  return axios.post<LegalUnit>('/api/legal-units', legalUnit)
}

export function updateLegalUnit(
  legalUnit: Omit<PartialDeep<LegalUnit>, '@id'> & { '@id': LegalUnit['@id'] }
) {
  return axios.put<LegalUnit>(legalUnit['@id'], legalUnit)
}

export function createOrganisationalGroup(organisationalGroup: Partial<OrganisationalGroup>) {
  return axios.post<OrganisationalGroup>('/api/organisational-groups', organisationalGroup)
}

export function updateOrganisationalGroup(
  organisationalGroup: Omit<Partial<OrganisationalGroup>, '@id'> & {
    '@id': OrganisationalGroup['@id']
  }
) {
  return axios.put<OrganisationalGroup>(organisationalGroup['@id'], organisationalGroup)
}

export function createPermanentEstablishment(
  permanentEstablishment: Pick<
    PermanentEstablishment,
    'refId' | 'name' | 'parentLegalUnit' | 'verified' | 'legalName'
  >
) {
  return axios.post<PermanentEstablishment>('/api/permanent-establishments', permanentEstablishment)
}

export function updatePermanentEstablishment(
  permanentEstablishment: Omit<PartialDeep<PermanentEstablishment>, '@id'> & {
    '@id': PermanentEstablishment['@id']
  }
) {
  return axios.put<PermanentEstablishment>(permanentEstablishment['@id'], permanentEstablishment)
}
