<script setup lang="ts">
import { computed } from 'vue'
import AppLoader from '@js/components/loader/AppLoader.vue'
import { usePageStore } from '@js/stores/page'

const pageStore = usePageStore()
const loading = computed(() => pageStore.loading)
</script>

<template>
  <teleport to="#teleport-target">
    <transition name="fade" appear>
      <div v-if="loading" class="fixed inset-0 z-40 bg-black bg-opacity-25">
        <div
          class="fixed left-1/2 top-1/2 -ml-12 -mt-12 flex size-24 rounded-bl-[50px] rounded-tr-[50px] bg-white shadow-md"
        >
          <AppLoader class="flex-grow" />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
