import axios from 'axios'
import Routing from '@js/Routing'
import type { TaskShortName } from '@js/model/task'

type TaskInfo = {
  assignee?: {
    valid: boolean
    id: number
  }
  extra?: {
    listPath?: string
  }
  id?: number
  optionsForNew?: Record<string, string> | null
  readableName?: string
  shortName: TaskShortName | undefined
  taskId?: string
}

type TaskMetadata = {
  readableName?: string
  shortName: TaskShortName | undefined
  extra?: {
    listPath?: string
  }
}

export function fetchTaskInformation(shortName: string, id: number) {
  return axios.get<TaskInfo>(
    Routing.generate('u2_entityinformation_entityinformation', {
      shortName,
      id,
    })
  )
}

export function fetchTaskMetadata(shortName: string) {
  return axios.get<TaskMetadata>(
    Routing.generate('u2_entityinformation_entitymetadata', { shortName })
  )
}

export const entityApi = {
  fetchTaskInformation,
  fetchTaskMetadata,
}
