import * as CountryApi from '@js/api/countryApi'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import type { Country } from '@js/model/country'
import type { MaybeRef } from '@vueuse/core'

export const countries = createQueryKeys('countries', {
  all: {
    queryKey: null,
    queryFn: () => CountryApi.fetchAllCountries().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      CountryApi.fetchCountriesByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRef<Country['id']>) => ({
    queryKey: [id],
    queryFn: () => CountryApi.fetchCountryById(unref(id)).then((response) => response.data),
  }),
})
