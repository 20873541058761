import * as BranchApi from '@js/api/branchApi'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import type { Branch } from '@js/model/branch'

export const branches = createQueryKeys('branches', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      BranchApi.fetchBranchByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRef<Branch['id'] | undefined>) => {
    const value = toValue(id)
    invariant(value !== undefined, 'id is required')
    return {
      queryKey: [id],
      queryFn: () => BranchApi.fetchBranchById(value).then((response) => response.data),
    }
  },
})
