import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { transitionConditionTypeApi } from '@js/api/transitionConditionTypeApi'
import type { ConditionType } from '@js/api/transitionConditionTypeApi'
import type { MaybeRef } from '@vueuse/core'

export const conditionTypes = createQueryKeys('conditionTypes', {
  all: {
    queryKey: null,
    queryFn: () => transitionConditionTypeApi.fetchAllTaskTransitionConditionTypes(),
  },
  single: (id: MaybeRef<ConditionType['id']>) => {
    return {
      queryKey: [id],
      queryFn: () =>
        transitionConditionTypeApi
          .fetchTaskTransitionConditionTypeById(unref(id))
          .then((response) => response.data),
    }
  },
})
