<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import $ from 'jquery'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import MediaQuery from '@js/utilities/media-query'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import { usePageStore } from '@js/stores/page'

const resizer = ref<HTMLElement>()
const sidebar = ref<HTMLElement>()

const pageStore = usePageStore()
const isCollapsed = computed(() => pageStore.sidebarCollapsed)
const width = computed(() => (pageStore.sidebarCollapsed ? 45 : pageStore.sidebarWidth))

onMounted(() => {
  nextTick(() => {
    // Handle the scrolling for sticking the sidebar
    const $window = $(window) as JQuery<Window>
    const $header = $('#header') as JQuery<HTMLElement>
    const onScroll = throttle(() => {
      if (!sidebar.value) {
        return
      }
      const headerHeight = $header.outerHeight() as number
      const scrollTop = Number($window.scrollTop())
      const headerVisibleHeight = scrollTop > headerHeight ? 0 : headerHeight - scrollTop
      const outerHeight = $window.outerHeight() ?? 0
      sidebar.value.style.height = `${outerHeight - headerVisibleHeight}px`
    }, 300)
    $window.off('scroll', onScroll)
    $window.on('scroll', onScroll)

    if (MediaQuery.menuMobileMaxWidth.matches) {
      collapse()
    }
  })
})
function collapse() {
  pageStore.sidebarCollapsed = true
  $(document).trigger('sidebar:resize')
}

const storeWidth = debounce((width) => (pageStore.sidebarWidth = width), 500)
function dragMouse(event: MouseEvent) {
  if (!sidebar.value || event.buttons < 1) {
    stopResize()
    return
  }
  const width = event.pageX
  if (width > 120 && width < 600) {
    sidebar.value.style.width = `${width}px`
    $(document).trigger('sidebar:resize')

    storeWidth(width)
  }
}
function expand() {
  pageStore.sidebarCollapsed = false
  $(document).trigger('sidebar:resize')
}

function startResize() {
  if (!isCollapsed.value) {
    document.getElementById('main')?.addEventListener('mousemove', dragMouse)
  }
}
function stopResize() {
  document.getElementById('main')?.removeEventListener('mousemove', dragMouse)
}
</script>

<template>
  <div class="z-20 flex">
    <aside
      ref="sidebar"
      class="sidebar sticky inset-y-0 flex flex-col bg-gray-100 print:static print:float-none print:mx-0 print:overflow-visible print:bg-transparent"
      :class="{ 'collapsed print:hidden': isCollapsed }"
      :style="`width:${width}px;`"
    >
      <div class="h-auto w-full overflow-y-auto">
        <slot :is-collapsed="isCollapsed" />
      </div>

      <!--   Sidebar Toggle   -->
      <div
        class="mt-auto flex min-h-[40px] w-full border-t border-gray-200 bg-gray-100 text-center font-bold print:hidden"
      >
        <a
          v-if="isCollapsed"
          v-tooltip="Translator.trans('u2_core.expand_sidebar')"
          class="flex h-full w-full items-center justify-center text-gray-500 hover:text-gray-950 hover:no-underline focus:text-gray-950 focus:no-underline"
          @click="expand"
        >
          <SvgIcon icon="priority-medium" class="rotate-90" />
        </a>

        <a
          v-else
          class="flex h-full w-full items-center justify-center gap-x-0.5 text-gray-500 hover:text-gray-950 hover:no-underline focus:text-gray-950 focus:no-underline"
          @click="collapse"
        >
          <SvgIcon icon="priority-medium" class="-rotate-90" />
          {{ Translator.trans('u2.collapse') }}
        </a>
      </div>
    </aside>
    <div
      ref="resizer"
      class="w-[5px] border-r-4 border-white bg-gray-200 print:hidden"
      :class="[isCollapsed ? 'cursor-default' : 'cursor-col-resize']"
      @mousedown.prevent="startResize"
      @mouseup.prevent="stopResize"
    />
  </div>
</template>

<style>
.sidebar {
  height: calc(100vh - var(--app-header-height));

  .csspositionsticky & {
    max-height: 100vh;
  }

  .no-csspositionsticky & {
    position: relative;
  }

  /* TODO: Remove this when it is possible to add css to the sidebar menu content in breakdown.html.twig */
  li {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media print {
  .sidebar {
    height: auto !important;
    max-height: none !important;
    width: 100% !important;

    li {
      white-space: normal !important;
    }
  }
}
</style>
