import { createQueryKeys } from '@lukemorales/query-key-factory'
import * as AuthorizationProfileApi from '@js/api/authorizationProfileApi'

export const authorizationProfiles = createQueryKeys('authorizationProfiles', {
  all: null,
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            AuthorizationProfileApi.fetchAuthorizationProfileAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            AuthorizationProfileApi.fetchAuthorizationProfileInheritedAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            AuthorizationProfileApi.fetchAuthorizationProfileAssignedUserGroups(id).then(
              (response) => response.data
            ),
        },
      },
    }
  },
})
