import Routing from '@js/Routing'
import axios from 'axios'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { SavedFilterSubscription } from '@js/model/saved-filter'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const savedFilterSubscriptionApiBasePath = '/api/saved-filter-subscriptions'

export function fetchSavedFilterSubscriptionById(id: number) {
  return fetchSavedFilterSubscriptionByIri(`${savedFilterSubscriptionApiBasePath}/${id}`)
}

export function fetchSavedFilterSubscriptionByIri(iri: ApiResourceId) {
  return axios.get<SavedFilterSubscription>(iri)
}

export function fetchAllSavedFilterSubscriptions() {
  return fetchByQuery({ pagination: false })
}

export function fetchByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<SavedFilterSubscription>>(
    savedFilterSubscriptionApiBasePath,
    { params: { ...query } }
  )
}

export function createSavedFilterSubscription(
  payload: Pick<SavedFilterSubscription, 'name' | 'frequency' | 'active' | 'description'>
) {
  return axios.post<SavedFilterSubscription>(savedFilterSubscriptionApiBasePath, payload)
}

export function updateSavedFilterSubscription(payload: SavedFilterSubscription) {
  return axios.put<SavedFilterSubscription>(
    `${savedFilterSubscriptionApiBasePath}/${payload.id}`,
    payload
  )
}

export function removeSavedFilterSubscription(id: number) {
  return axios.delete<SavedFilterSubscription>(`${savedFilterSubscriptionApiBasePath}/${id}`)
}

export function fetchSavedFilterSubscriptionAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(
    `${savedFilterSubscriptionApiBasePath}/${id}/groups`,
    { params: { pagination: false } }
  )
}

export function updateSavedFilterSubscriptionAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.put<HydraCollectionResponse<UserGroup>>(
    `${savedFilterSubscriptionApiBasePath}/${id}/groups`,
    { groups: updatedGroups }
  )
}

export function fetchSavedFilterSubscriptionAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${savedFilterSubscriptionApiBasePath}/${id}/direct-users`,
    { params: { pagination: false } }
  )
}

export function updateSavedFilterSubscriptionAssignedUsers(
  id: number,
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.put<HydraCollectionResponse<User>>(
    `${savedFilterSubscriptionApiBasePath}/${id}/direct-users`,
    { directUsers: updatedUsers }
  )
}

export function fetchSavedFilterSubscriptionInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${savedFilterSubscriptionApiBasePath}/${id}/inherited-users`,
    { params: { pagination: false } }
  )
}

export function send(id: SavedFilterSubscription['id']) {
  return axios.post(Routing.generate('u2_savedfiltersubscriptions_forcerun', { id }))
}
