import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { Task } from '@js/model/task'
import type { Comment } from '@js/model/comment'

export const commentApiBasePath = '/api/comments'

export function fetchCommentById(id: Comment['id']) {
  return axios.get<Comment>(`${commentApiBasePath}/${id}`)
}

export function fetchCommentsByTaskId(taskId: Task['id']) {
  return axios.get<HydraCollectionResponse<Comment>>(`/api/tasks/${taskId}/comments`)
}

export function createComment(comment: Partial<Comment>) {
  return axios.post<Comment>(commentApiBasePath, comment)
}

export function updateComment(comment: Partial<Comment>) {
  return axios.put<Comment>(`${comment['@id']}`, comment)
}

export function deleteCommentById(id: Comment['id']) {
  return axios.delete(`${commentApiBasePath}/${id}`)
}
