<script lang="ts" setup>
import { computed } from 'vue'
import ButtonBase from '@js/components/buttons/ButtonBase.vue'
import Translator from '@js/translator'
import type { TranslationId } from '@js/translator'
import type { MenuItem as MenuItemType } from '@js/model/menu'

const props = defineProps<{
  item: MenuItemType
}>()

const label = computed(() => Translator.trans(props.item.label as TranslationId))
const emits = defineEmits<(event: 'click', payload: MenuItemType) => void>()
</script>

<template>
  <li
    :class="{
      'drop-down-menu-separator': item.extras.separator,
    }"
  >
    <span v-if="item.extras.separator">
      {{ label }}
    </span>

    <ButtonBase
      v-else-if="item.extras.vueRoute && item.children.length === 0"
      class="text-left"
      :to="item.extras.vueRoute"
      @click="emits('click', item)"
    >
      {{ label }}
    </ButtonBase>

    <ButtonBase v-else-if="item.level === 1" class="js-drop-down-menu-trigger text-left">
      {{ label }}
    </ButtonBase>

    <ButtonBase
      v-else-if="item.extras.vueRoute"
      class="text-left"
      :class="[
        {
          'js-drop-down-menu-trigger': item.children.length,
          'drop-down-menu-arrow-right': item.children.length && item.level !== 1,
        },
      ]"
    >
      {{ label }}
    </ButtonBase>

    <ButtonBase v-else class="w-full text-left" @click.prevent="emits('click', item)">
      {{ label }}
    </ButtonBase>

    <ul v-if="item.children && item.children.length" class="menu_level_1 hidden">
      <MenuItem
        v-for="child in item.children"
        :key="child.name"
        :item="child"
        @click="emits('click', $event)"
      />
    </ul>
  </li>
</template>
