import axios from 'axios'
import type { Check, CheckWithStatusesAsObjects } from '@js/model/check'
import type { Status } from '@js/model/status'
import type {
  ApiPaginationQueryParams,
  ApiResource,
  ApiResourceId,
  HydraCollectionResponse,
} from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export type WorkflowBinding = ApiResource & {
  id: number
  bindingId: string
  workflow: ApiResourceId
  readableName: string
}
export type Workflow = ApiResource & {
  id: number
  name: string
  initialStatus: Status
  transitions: Array<ApiResourceId>
  bindings: Array<WorkflowBinding>
  manualReviewEnabled: boolean
  checks: Array<ApiResourceId>
  fieldConfigurationStatuses: Array<ApiResourceId>
}

export type WorkflowCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'bindings.bindingId'?: string | Array<string>
  search?: string | Array<string>
}

export const workflowApiBasePath = '/api/workflows'

export function fetchWorkflowByBindingId(bindingId: string) {
  return fetchWorkflowsByQuery({ 'bindings.bindingId': bindingId })
}

export function fetchWorkflowById(id: number) {
  return fetchWorkflowByIri(`${workflowApiBasePath}/${id}`)
}

export function fetchWorkflowByIri(iri: ApiResourceId) {
  return axios.get<Workflow>(iri)
}

export function fetchWorkflowsByQuery(query?: WorkflowCollectionQuery) {
  return axios.get<HydraCollectionResponse<Workflow>>(workflowApiBasePath, {
    params: { ...query },
  })
}

export function deleteWorkflowById(id: Workflow['id']) {
  return axios.delete(`${workflowApiBasePath}/${id}`)
}

export function fetchWorkflowCheckByIri(iri: Check['@id']) {
  return axios.get<CheckWithStatusesAsObjects>(iri)
}

export function deleteWorkflowCheckByIri(iri: Check['@id']) {
  return axios.delete(iri)
}

export function updateWorkflowCheck(check: Check) {
  return axios.put<CheckWithStatusesAsObjects>(check['@id'], check)
}

export function createWorkflowCheck(check: Partial<Check>) {
  return axios.post<CheckWithStatusesAsObjects>('/api/workflow-checks', check)
}

export function updateWorkflow(workflow: Partial<Workflow> & { '@id': Workflow['@id'] }) {
  return axios.put<Workflow>(workflow['@id'], workflow)
}

export function createWorkflow(workflow: Partial<Workflow>) {
  return axios.post<Workflow>(workflowApiBasePath, workflow)
}
