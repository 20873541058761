import axios from 'axios'
import { PermissionMasks } from '@js/model/permission'
import type { GroupPermission, UserPermission } from '@js/model/permission'
import type { ItemCountryReport } from '@js/api/itemCountryReportApi'
import type { Datasheet } from '@js/model/datasheet'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { AxiosResponse } from 'axios'

export const basePath = '/api/layout-collections'
type LayoutCollectionForm = { name: string; public: boolean }

export function fetchDatasheetCollectionsByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  search?: string | Array<string>
  permission?: 1 | 4 | 8 | 128
}) {
  return axios.get<HydraCollectionResponse<DatasheetCollection>>(basePath, {
    params: { ...query },
  })
}

export function fetchDatasheetCollectionById(id: string) {
  return axios.get<DatasheetCollection>(`${basePath}/${id}`)
}

export function deleteDatasheetCollection(payload: Pick<DatasheetCollection, '@id'>) {
  if (!payload['@id']) throw new Error('Unable to delete record without @id')

  return axios.delete<DatasheetCollection>(payload['@id'])
}

export function createDatasheetCollection(payload: LayoutCollectionForm) {
  return axios.post<LayoutCollectionForm, AxiosResponse<DatasheetCollection>>(
    `${basePath}`,
    payload
  )
}

export function updateDatasheetCollection(
  layoutCollection: DatasheetCollection,
  payload: LayoutCollectionForm
) {
  if (!layoutCollection['@id']) throw new Error('Unable to update record without @id in payload')

  return axios.patch<DatasheetCollection>(layoutCollection['@id'], payload)
}

export function updateDatasheetCollectionAssignedLayouts(
  layoutCollectionId: DatasheetCollection['id'],
  payload: Array<Datasheet['@id']>
) {
  return axios.put(`${basePath}/${layoutCollectionId}/layouts`, { layouts: payload })
}

export function fetchDatasheetCollectionAssignedLayouts(
  layoutCollectionId: DatasheetCollection['id']
) {
  return axios.get<HydraCollectionResponse<Datasheet>>(
    `${basePath}/${layoutCollectionId}/layouts?permission=` + PermissionMasks.VIEW
  )
}

export function fetchDatasheetCollectionAssignedLayoutsForAdministrators(
  layoutCollectionId: DatasheetCollection['id']
) {
  return axios.get<HydraCollectionResponse<Datasheet>>(`${basePath}/${layoutCollectionId}/layouts`)
}

export function fetchDatasheetCollectionAssignedItemCountryReports(
  layoutCollectionId: DatasheetCollection['id']
) {
  return axios.get<HydraCollectionResponse<ItemCountryReport>>(
    `${basePath}/${layoutCollectionId}/item-country-reports`
  )
}

export function fetchDatasheetCollectionUserPermissions(layoutCollection: DatasheetCollection) {
  return axios.get<HydraCollectionResponse<UserPermission>>(
    `${layoutCollection['@id']}/user-permissions`
  )
}

export function fetchDatasheetCollectionGroupPermissions(layoutCollection: DatasheetCollection) {
  return axios.get<HydraCollectionResponse<GroupPermission>>(
    `${layoutCollection['@id']}/group-permissions`
  )
}

export function updateDatasheetCollectionUserPermissions(
  layoutCollection: DatasheetCollection,
  userPermissions: Array<Omit<UserPermission, '@id' | 'id'>>
) {
  return axios.put<HydraCollectionResponse<UserPermission>>(
    `${layoutCollection['@id']}/user-permissions`,
    { userPermissions }
  )
}

export function updateDatasheetCollectionGroupPermissions(
  layoutCollection: DatasheetCollection,
  groupPermissions: Array<Omit<GroupPermission, '@id' | 'id'>>
) {
  return axios.put<HydraCollectionResponse<GroupPermission>>(
    `${layoutCollection['@id']}/group-permissions`,
    { groupPermissions }
  )
}

export const datasheetCollectionApi = {
  basePath,
  fetchDatasheetCollectionsByQuery,
  fetchDatasheetCollectionById,
  deleteDatasheetCollection,
  createDatasheetCollection,
  updateDatasheetCollection,
  updateDatasheetCollectionAssignedLayouts,
  fetchDatasheetCollectionAssignedLayouts,
  fetchDatasheetCollectionAssignedLayoutsForAdministrators,
  fetchDatasheetCollectionAssignedItemCountryReports,
  fetchDatasheetCollectionUserPermissions,
  fetchDatasheetCollectionGroupPermissions,
  updateDatasheetCollectionUserPermissions,
  updateDatasheetCollectionGroupPermissions,
}
