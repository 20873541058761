import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import * as WorkflowApi from '@js/api/workflowApi'
import type { MaybeRefOrGetter } from 'vue'
import type { Workflow, WorkflowCollectionQuery } from '@js/api/workflowApi'

export const workflows = createQueryKeys('workflows', {
  list: (filters?: MaybeRefOrGetter<WorkflowCollectionQuery>) => ({
    queryKey: [filters],
    queryFn: () =>
      WorkflowApi.fetchWorkflowsByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),

  single: (id: MaybeRefOrGetter<Workflow['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = toValue(id)
        invariant(idValue)
        return WorkflowApi.fetchWorkflowById(idValue).then((response) => response.data)
      },
    }
  },
})
