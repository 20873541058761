import axios from 'axios'
import type {
  FieldConfigurationStatusSet,
  FieldConfigurationStatusSetWithIris,
} from '@js/model/fieldConfigurationStatusSet'
import type { FieldConfiguration } from '@js/model/fieldConfiguration'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'

export const fieldConfigurationApiBasePath = '/api/field-configurations'
export const fieldConfigurationStatusSetApiBasePath = '/api/field-configuration-status-sets'

type FieldConfigurationCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  name?: string
}

export function fetchFieldConfigurationsByQuery(query: FieldConfigurationCollectionQuery) {
  return axios.get<HydraCollectionResponse<FieldConfiguration>>(fieldConfigurationApiBasePath, {
    params: { ...query },
  })
}

export function deleteFieldConfiguration(fieldConfiguration: FieldConfiguration) {
  return axios.delete(`${fieldConfiguration['@id']}`)
}

export function fetchFieldConfigurationById(id: FieldConfiguration['id']) {
  return axios.get<FieldConfiguration>(`${fieldConfigurationApiBasePath}/${id}`)
}

export function updateFieldConfiguration(
  fieldConfiguration: Partial<FieldConfiguration> & { '@id': FieldConfiguration['@id'] }
) {
  return axios.put<FieldConfiguration>(fieldConfiguration['@id'], fieldConfiguration)
}

export function createFieldConfiguration(fieldConfiguration: Pick<FieldConfiguration, 'name'>) {
  return axios.post<FieldConfiguration>(fieldConfigurationApiBasePath, fieldConfiguration)
}

export function deleteFieldConfigurationStatusSet(
  fieldConfigurationStatusSet: FieldConfigurationStatusSet
) {
  return axios.delete(fieldConfigurationStatusSet['@id'])
}

export function fetchFieldConfigurationStatusSetByIri(iri: FieldConfigurationStatusSet['@id']) {
  return axios.get<FieldConfigurationStatusSet>(iri)
}
export function updateFieldConfigurationStatusSet(
  fieldConfigurationStatusSet: FieldConfigurationStatusSetWithIris
) {
  return axios.put<FieldConfigurationStatusSet>(
    fieldConfigurationStatusSet['@id'],
    fieldConfigurationStatusSet
  )
}

export function createFieldConfigurationStatusSet(
  fieldConfigurationStatusSet: Pick<FieldConfigurationStatusSetWithIris, 'fieldConfiguration'>
) {
  return axios.post<FieldConfigurationStatusSet>(
    fieldConfigurationStatusSetApiBasePath,
    fieldConfigurationStatusSet
  )
}
