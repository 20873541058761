import axios from 'axios'
import Routing from '@js/Routing'
import type Operators from '@js/components/legacy/table/uql/operators'
import type { TaskListInfo, TaskShortName } from '@js/model/task'
import type { MessageBag } from '@js/stores/notifications'
import type { FilterGroup, TableColumn } from '@js/stores/task-list'
import type { LocationQuery } from 'vue-router'

export type TableField = {
  uniqueName: string
  choices?: Record<string, string>
  type: {
    name: string
    default: keyof typeof Operators.operatorMap
    available: Array<keyof typeof Operators.operatorMap>
  }
  metadata: {
    filterable: boolean
    hidden: boolean
    required: boolean
    selectedByDefault: boolean
    sortable: boolean
    name: string // translation key
  }
}
export type TableDomData = {
  name: string
  state: {
    sor: Array<[string, 'ASC' | 'DESC']>
    pag: [number, number]
    sel: Array<number>
    uql: string
    clmns: Array<string>
    filter: string | undefined
  }
  fields: Array<TableField>
  functions: Record<
    string,
    {
      name: string
      string: string
      arguments?: unknown
    }
  >
  columns: Array<TableColumn>
  config: {
    hasData: boolean
    pagination: {
      current: number
      count: number
      page_range: number
      total_items: number
    }
    paginationEnabled: boolean
  }
  records: Array<
    Record<string, unknown> & {
      Id: number
      select: boolean
      actions: boolean
    }
  >
  messages: MessageBag
}

export function fetchTaskTableByQuery(shortName: TaskShortName, query?: LocationQuery) {
  return axios.get<TableDomData>(Routing.generate('u2_tasktype_table', { shortName }), {
    params: query,
  })
}

export function fetchTaskListInfo(shortName: TaskShortName, queryParameter: LocationQuery) {
  return axios.get<TaskListInfo>(
    Routing.generate('u2_entityinformation_listinfo', {
      shortName,
      ...queryParameter,
    })
  )
}

export function fetchTaskTableUql(filter: FilterGroup) {
  return axios.post<{ uql: string }>(
    Routing.generate('table_bundle_uql_generate_from_filter'),
    filter
  )
}

export const taskTypeApi = {
  fetchTaskTableByQuery,
  fetchTaskListInfo,
  fetchTaskTableUql,
}
