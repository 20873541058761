import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import { choiceApi } from '@js/api/choiceApi'
import type { ChoiceCollectionEndpoint } from '@js/model/choice'

export const choices = createQueryKeys('choices', {
  list: (collectionPath: ChoiceCollectionEndpoint, filters) => ({
    queryKey: [collectionPath, filters],
    queryFn: () =>
      choiceApi
        .fetchChoiceByQuery(collectionPath, { ...toValue(filters) })
        .then((response) => response.data),
  }),
})
