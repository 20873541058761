import axios from 'axios'
import type { ApiPaginationQueryParams, ApiResource, HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export type LegalForm = ApiResource & {
  id: number
  enabled: boolean
  name: string
}

export const legalFormApiBasePath = '/api/configuration/legal-forms'

type LegalFormCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[enabled]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchLegalFormsByQuery(query?: LegalFormCollectionQuery) {
  return axios.get<HydraCollectionResponse<LegalForm>>(legalFormApiBasePath, {
    params: { ...query },
  })
}

export function createLegalForm(legalForm: Partial<LegalForm>) {
  return axios.post<LegalForm>(legalFormApiBasePath, legalForm)
}

export function updateLegalForm(legalForm: Partial<LegalForm>) {
  return axios.put<LegalForm>(`${legalFormApiBasePath}/${legalForm.id}`, legalForm)
}

export function deleteLegalForm(legalForm: LegalForm) {
  return axios.delete(`${legalForm['@id']}`)
}

export function deleteLegalFormById(id: LegalForm['id']) {
  return axios.delete(`${legalFormApiBasePath}/${id}`)
}

export function fetchLegalFormById(id: LegalForm['id']) {
  return axios.get<LegalForm>(`${legalFormApiBasePath}/${id}`)
}
