import $ from 'jquery'

$.fn.dropDownMenu = function () {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const menuBox = this
  const $menuItem = menuBox.find('li') // get all menu items

  function closeMenu(activeMenuFilter: JQuery<HTMLUListElement>) {
    activeMenuFilter
      .removeClass('active')
      .find('li')
      .removeClass('active')
      .end()
      .find('ul ul')
      .fadeOut('fast')
  }

  function openMenu(
    menuBox: JQuery<HTMLElement>,
    thisItem: JQuery<HTMLElement>,
    isMenuActive: boolean
  ) {
    const thisMenu = thisItem.closest('nav.drop-down-menu')
    const activeMenuFilter = $('nav.drop-down-menu').filter('.active') as JQuery<HTMLUListElement>
    if (thisMenu.attr('id') !== activeMenuFilter.attr('id')) {
      closeMenu(activeMenuFilter)
    }

    if (!isMenuActive) {
      menuBox.addClass('active')
    }

    thisItem.addClass('active').children('ul').show().addClass('active')
  }

  $menuItem.on({
    click: function (event) {
      const thisItem = $(this) as JQuery<HTMLElement>
      const isMenuActive = menuBox.hasClass('active')

      if (!isMenuActive && thisItem.find('ul').length) {
        openMenu(menuBox, thisItem, isMenuActive)
        event.preventDefault()
      }
    },
    mouseenter: function (e) {
      e.stopPropagation()
      const thisItem = $(this)
      thisItem.closest('ul').find('li').removeClass('active').find('ul').hide()

      const isMenuActive = menuBox.hasClass('active')
      if (isMenuActive && thisItem.find('ul').length) {
        openMenu(menuBox, thisItem, isMenuActive)
      }
    },
  })

  $('body')
    .off('click.dropdownmenu')
    .on('click.dropdownmenu', (event) => {
      const activeMenuFilter = $('nav.drop-down-menu').filter('.active') as JQuery<HTMLUListElement>
      if (activeMenuFilter.length) {
        if (
          $(event.target).parents('nav.drop-down-menu').length !== 1 ||
          !$(event.target).closest('ul').hasClass('first-level')
        ) {
          closeMenu(activeMenuFilter)
        }
      }
    })

  return this
}
