import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import * as DatasheetApi from '@js/api/datasheetApi'
import { datasheetFieldApi } from '@js/api/datasheetFieldApi'
import { itemApi } from '@js/api/itemApi'
import type { MaybeRef } from '@vueuse/core'
import type { Datasheet, Field } from '@js/model/datasheet'

export const datasheets = createQueryKeys('datasheets', {
  all: {
    queryKey: null,
    queryFn: () => DatasheetApi.fetchAllDatasheets().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      DatasheetApi.fetchDatasheetsByQuery({
        ...unref(filters),
      }),
  }),
  single: (id: MaybeRef<Datasheet['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => DatasheetApi.fetchDatasheetById(unref(id)).then((response) => response.data),
      contextQueries: {
        template: {
          queryKey: [id],
          queryFn: () =>
            DatasheetApi.downloadDatasheetTemplate(unref(id)).then((response) => response.data),
        },
        fields: {
          queryKey: [id],
          queryFn: () => datasheetFieldApi.fetchAllDatasheetFields(unref(id)),
          contextQueries: {
            single: (fieldId: MaybeRef<Field['id']>) => {
              return {
                queryKey: [fieldId, id],
                queryFn: () =>
                  datasheetFieldApi
                    .fetchDatasheetFieldById(unref(fieldId), unref(id))
                    .then((response) => response.data),
              }
            },
          },
        },
        items: {
          queryKey: [id],
          queryFn: () =>
            itemApi.fetchDatasheetItemsByQuery({
              pagination: false,
              'datasheet.id': unref(id),
            }),
        },
        layoutCollections: {
          queryKey: [id],
          queryFn: () =>
            DatasheetApi.fetchDatasheetCollectionsForDatasheet(unref(id)).then(
              (response) => response.data
            ),
        },
      },
    }
  },
})
