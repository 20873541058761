import axios from 'axios'
import Routing from '@js/Routing'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { RoleResource } from '@js/model/role'
import type { Unit } from '@js/model/unit'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const userGroupBasePath = '/api/user-groups'
type UserGroupCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[description]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchAllUserGroups() {
  return axios.get<HydraCollectionResponse<UserGroup>>(userGroupBasePath, {
    params: { pagination: false },
  })
}

export function fetchUserGroupsByQuery(query: UserGroupCollectionQuery) {
  return axios.get<HydraCollectionResponse<UserGroup>>(userGroupBasePath, {
    params: { ...query },
  })
}
export function fetchUserGroupById(id: UserGroup['id']) {
  return axios.get<UserGroup>(`${userGroupBasePath}/${id}`)
}

export function deleteUserGroupById(id: UserGroup['id']) {
  return axios.delete(`${userGroupBasePath}/${id}`)
}

export function fetchUserGroupAssignedRoles(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(`${userGroupBasePath}/${id}/roles`, {
    params: { pagination: false },
  })
}

export function updateUserGroupAssignedRoles(
  id: UserGroup['id'],
  updatedRoles: Array<NonNullable<RoleResource['@id']>>
) {
  return axios.put<HydraCollectionResponse<RoleResource>>(`${userGroupBasePath}/${id}/roles`, {
    roles: updatedRoles,
  })
}

export function fetchUserGroupInheritedRoles(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(
    `${userGroupBasePath}/${id}/inherited-roles`,
    {
      params: { pagination: false },
    }
  )
}

export function fetchUserGroupAssignedUnits(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<Unit>>(`${userGroupBasePath}/${id}/units`, {
    params: { pagination: false },
  })
}

export function updateUserGroupAssignedUnits(
  id: UserGroup['id'],
  updatedUnits: Array<NonNullable<Unit['@id']>>
) {
  return axios.put<HydraCollectionResponse<Unit>>(`${userGroupBasePath}/${id}/units`, {
    units: updatedUnits,
  })
}

export function fetchUserGroupAssignedUsers(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${userGroupBasePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

export function updateUserGroupAssignedUsers(
  id: UserGroup['id'],
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.put<HydraCollectionResponse<User>>(`${userGroupBasePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

export function createUserGroup(userGroup: Partial<UserGroup>) {
  return axios.post<UserGroup>(userGroupBasePath, userGroup)
}

export function updateUserGroup(userGroup: Partial<UserGroup>) {
  return axios.put<UserGroup>(`${userGroupBasePath}/${userGroup.id}`, userGroup)
}

export function fetchUserGroupRights(userGroup: UserGroup) {
  return axios.get<Record<string, Array<string>>>(
    Routing.generate('u2_usergroup_usergrouprightsjson', { id: userGroup.id })
  )
}
