export function transformIfDefined<T, U>(
  value: T | undefined,
  transform: (val: T) => U
): U | undefined {
  if (value === undefined) {
    return undefined
  }

  return transform(value)
}

export function transformIfTruthy<T, U>(
  value: T | undefined,
  transform: (val: T) => U
): U | undefined {
  if (value) {
    return transform(value)
  }
  return undefined
}

export const numberToString = (value: number | undefined) => transformIfDefined(value, String)
export const stringToNumber = (value: string | undefined) => transformIfDefined(value, Number)
