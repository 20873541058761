import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import * as AuthorizationApi from '@js/api/authorizationApi'

export const authorizations = createQueryKeys('authorizations', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      AuthorizationApi.fetchAuthorizationsByQuery({ ...toValue(filters) }).then(
        (response) => response.data
      ),
  }),
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            AuthorizationApi.fetchAuthorizationAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            AuthorizationApi.fetchAuthorizationInheritedAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            AuthorizationApi.fetchAuthorizationAssignedUserGroups(id).then(
              (response) => response.data
            ),
        },
      },
    }
  },
})
