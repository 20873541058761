<script setup lang="ts">
import { ref, watchEffect } from 'vue'

defineProps<{
  text?: string | number | undefined
}>()

const target = ref()
const isOverflown = ref(false)
watchEffect(() => {
  isOverflown.value = target.value?.scrollWidth > target.value?.clientWidth
})
</script>

<template>
  <span ref="target" :title="isOverflown ? text?.toString() : undefined">{{ text }}</span>
</template>
