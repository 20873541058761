import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { Auditor } from '@js/model/auditor'
import type { PartialDeep } from 'type-fest'

const basePath = '/api/configuration/auditors'

type AuditorCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[enabled]'?: SortingDirection
  search?: string | Array<string>
}
export function fetchAuditorByQuery(query?: AuditorCollectionQuery) {
  return axios.get<HydraCollectionResponse<Auditor>>(basePath, {
    params: { ...query },
  })
}
export function createAuditor(auditor: Pick<Auditor, 'name' | 'address' | 'enabled'>) {
  return axios.post<Auditor>(basePath, auditor)
}

export function updateAuditor(auditor: PartialDeep<Auditor>) {
  return axios.put<Auditor>(`${basePath}/${auditor.id}`, auditor)
}

export function deleteAuditor(auditor: Auditor) {
  return axios.delete(`${auditor['@id']}`)
}

export function deleteAuditorById(id: Auditor['id']) {
  return axios.delete(`${basePath}/${id}`)
}

export function fetchAuditorById(id: Auditor['id']) {
  return axios.get<Auditor>(`${basePath}/${id}`)
}

export const auditorApi = {
  basePath,
  createAuditor,
  deleteAuditor,
  deleteAuditorById,
  fetchAuditorById,
  fetchAuditorByQuery,
  updateAuditor,
}
