<script setup lang="ts">
import { computed } from 'vue'
import AppLink from '@js/components/buttons/AppLink.vue'
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    to?: RouteLocationRaw
    tooltip?: string
    type?: 'button' | 'reset' | 'submit'
  }>(),
  {
    disabled: false,
    to: undefined,
    tooltip: undefined,
    type: 'button',
  }
)

defineSlots<{ default?: (props: Record<string, never>) => unknown }>()

const emit = defineEmits<(event: 'click', payload: MouseEvent) => void>()

const isDisabled = computed(() => props.disabled)
const onClick = (event: MouseEvent) => {
  if (isDisabled.value) {
    event.preventDefault()
    return false
  }
  emit('click', event)
}
</script>

<template>
  <AppLink
    v-if="to"
    v-tooltip="tooltip"
    :to="to"
    :disabled="isDisabled"
    :aria-label="($attrs['aria-label'] as string) ?? props.tooltip"
    :aria-disabled="isDisabled"
    @click="onClick"
  >
    <slot />
  </AppLink>
  <button
    v-else
    v-tooltip="tooltip"
    :type="type"
    :disabled="isDisabled"
    :aria-label="($attrs['aria-label'] as string) ?? props.tooltip"
    :aria-disabled="isDisabled"
    @click="onClick"
  >
    <slot />
  </button>
</template>
