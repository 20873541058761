import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { unref } from 'vue'
import { attachmentApi } from '@js/api/attachmentApi'
import type { ApiResourceId } from '@js/types'
import type { MaybeRef } from '@vueuse/core'

export const attachments = createQueryKeys('attachments', {
  all: (iri: MaybeRef<ApiResourceId | undefined>) => {
    return {
      queryKey: [iri],
      queryFn: () => {
        const value = unref(iri)
        invariant(value)
        return attachmentApi
          .fetchAllAttachments(value)
          .then((response) => response.data['hydra:member'] ?? [])
      },
    }
  },
})
