<script setup lang="ts">
import { fetchAbout } from '@js/api/appApi'
import { onMounted, ref } from 'vue'
import AppDialog from '@js/components/AppDialog.vue'
import Translator from '@js/translator'

const emit = defineEmits<(event: 'close') => void>()
const loading = ref(false)
const version = ref<string>()

onMounted(async () => {
  loading.value = true
  try {
    const { data } = await fetchAbout()
    version.value = data.version
  } finally {
    loading.value = false
  }
})
const close = () => emit('close')
</script>

<template>
  <AppDialog :loading="loading" @close="close">
    <section
      class="flex h-64 w-96 max-w-full flex-col items-center justify-evenly p-3 text-gray-700"
    >
      <template v-if="version">
        <img class="w-32" src="/assets/img/u2-logo.svg?url" alt="U²" />

        {{ Translator.trans('u2_core.given_version', { version: version }) }}

        <a
          href="https://universalunits.com"
          :title="Translator.trans('u2_core.go_to_the_universal_units_website')"
          target="_blank"
          >universalunits.com</a
        >

        <a href="mailto:info@universalunits.com">info@universalunits.com</a>

        © 2012-{{ new Date().getFullYear() }} Universal Units GmbH<br />
      </template>
    </section>
  </AppDialog>
</template>
