<script setup lang="ts">
import { useCssVar } from '@vueuse/core'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useHasWindowVerticalScrollbar } from '@js/composable/useHasWindowVerticalScrollbar'
import AppHeader from '@js/components/AppHeader.vue'
import AppNotifications from '@js/components/notifications/AppNotifications.vue'
import SystemMessages from '@js/components/system-messages/SystemMessages.vue'
import { usePageStore } from '@js/stores/page'

const pageStore = usePageStore()
const scrollbarWidth = useCssVar('--scrollbar-width')

const route = useRoute()
const isWide = computed(() => route.meta.layoutFormat === 'wide')

const { hasVerticalScrollbar } = useHasWindowVerticalScrollbar()

watch(
  hasVerticalScrollbar,
  (newHasScrollBar) => {
    scrollbarWidth.value = newHasScrollBar ? pageStore.scrollbarWidth : '0px'
  },
  { immediate: true }
)
</script>

<template>
  <div :class="{ 'inline-block print:block': isWide }">
    <AppNotifications />

    <div
      :class="{
        'sticky left-0 z-40 flex w-[calc(100vw-var(--scrollbar-width))] flex-col': isWide,
      }"
    >
      <SystemMessages />

      <AppHeader class="bg-off-black" />
    </div>
    <div id="main" class="page-content-container flex" role="main">
      <slot />
    </div>
  </div>
</template>

<style>
@media print {
  .page-content-container {
    display: block !important;
  }
}
</style>
