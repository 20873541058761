import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import * as FieldConfigurationApi from '@js/api/fieldConfigurationApi'
import type { MaybeRef } from '@vueuse/core'
import type { FieldConfiguration } from '@js/model/fieldConfiguration'

export const fieldConfigurations = createQueryKeys('fieldConfigurations', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      FieldConfigurationApi.fetchFieldConfigurationsByQuery({ ...toValue(filters) }).then(
        (response) => response.data
      ),
  }),
  single: (id: MaybeRef<FieldConfiguration['id']>) => ({
    queryKey: [id],
    queryFn: () =>
      FieldConfigurationApi.fetchFieldConfigurationById(unref(id)).then(
        (response) => response.data
      ),
  }),
})
