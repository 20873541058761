<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { ref } from 'vue'
import AppMenu from '@js/components/menu/AppMenu.vue'
import GlobalSearch from '@js/components/GlobalSearch.vue'
import Routing from '@js/Routing'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import UserMenu from '@js/components/menu/UserMenu.vue'

const isGlobalSearchOpen = ref(false)
function open() {
  isGlobalSearchOpen.value = true
}
onKeyStroke('/', (event) => {
  if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
    return
  }
  event.preventDefault()
  open()
})
</script>

<template>
  <header id="header" class="flex h-auto items-center print:hidden">
    <router-link
      :to="{ name: 'AppHome' }"
      class="p-2 text-white no-underline hover:bg-gray-800"
      data-app-logo
    >
      <img
        class="h-[34px] w-auto max-w-[200px] object-contain text-center align-middle font-bold leading-[34px] text-u2"
        :src="Routing.generate('u2_dynamicassets_corplogo')"
        alt="U²"
      />
    </router-link>

    <AppMenu />

    <span class="ml-auto inline-flex items-center">
      <div class="pointer-events-auto relative ml-auto bg-slate-900">
        <button
          type="button"
          class="flex items-center rounded bg-slate-700 px-2 py-1.5 leading-6 text-slate-400 shadow-sm ring-1 ring-slate-900/10 hover:bg-slate-600 focus:outline-none focus-visible:ring-1 focus-visible:ring-blue-600 md:w-40 md:pl-3 md:pr-4 md:pr-5"
          @click="open"
        >
          <SvgIcon icon="search" size="large" />
          <span class="ml-2 hidden text-sm md:inline md:text-base"
            >{{ Translator.trans('u2.search') }}...</span
          >
          <kbd
            class="ml-auto hidden items-center rounded border border-slate-500 bg-transparent px-2 font-sans text-sm font-medium text-slate-500 md:inline-flex"
          >
            /
          </kbd>
        </button>
      </div>

      <GlobalSearch v-if="isGlobalSearchOpen" @close="isGlobalSearchOpen = false" />

      <UserMenu class="ml-3" />
    </span>
  </header>
</template>
