import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import * as DocumentTemplateApi from '@js/api/documentTemplateApi'
import type { MaybeRefOrGetter } from 'vue'
import type { DocumentTemplate } from '@js/model/document-template'

export const documentTemplates = createQueryKeys('documentTemplates', {
  single: (id: MaybeRefOrGetter<DocumentTemplate['id']>) => ({
    queryKey: [id],
    queryFn: () =>
      DocumentTemplateApi.fetchDocumentTemplateById(toValue(id)).then((response) => response.data),
    contextQueries: {
      sections: {
        queryKey: [id],
        queryFn: () =>
          DocumentTemplateApi.fetchDocumentTemplateSections(toValue(id)).then(
            (response) => response.data
          ),
      },
      renderedSections: {
        queryKey: [id],
        queryFn: () =>
          DocumentTemplateApi.fetchDocumentTemplateRenderedSections(toValue(id)).then(
            (response) => response.data
          ),
      },
    },
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      DocumentTemplateApi.fetchDocumentTemplatesByQuery(toValue(params)).then(
        (response) => response.data
      ),
  }),
})
