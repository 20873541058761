import axios from 'axios'
import type { DynamicAssetId } from '@js/model/dynamicAsset'

export const basePath = '/api/dynamic-assets'

export function fetchDynamicAsset(id: DynamicAssetId) {
  return axios.get<Blob>(`${basePath}/${id}`, { responseType: 'blob' })
}

export function updateDynamicAsset(id: DynamicAssetId, payload?: File) {
  const formData = new FormData()
  if (payload) {
    formData.append('uploadedFile', payload)
  }

  return axios.post(`${basePath}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const dynamicAssetApi = {
  fetchDynamicAsset,
  updateDynamicAsset,
}
