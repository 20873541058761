import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { workflowBindingApi } from '@js/api/workflowBindingApi'
import type { WorkflowBinding } from '@js/api/workflowApi'
import type { MaybeRef } from '@vueuse/core'

export const workflowBindings = createQueryKeys('workflowBindings', {
  single: (bindingId: MaybeRef<WorkflowBinding['bindingId']>) => {
    return {
      queryKey: [bindingId],
      queryFn: () =>
        workflowBindingApi
          .fetchWorkflowBindingById(unref(bindingId))
          .then((response) => response.data),
    }
  },
})
