import {
  fetchDashboardAssignedUserGroups,
  fetchDashboardAssignedUsers,
  fetchDashboardById,
  fetchDashboardInheritedAssignedUsers,
  fetchDashboardsByQuery,
} from '@js/api/dashboardApi'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toRef, unref } from 'vue'
import { dashboardWidgetApi } from '@js/api/dashboardWidgetApi'
import type { MaybeRefOrGetter } from 'vue'
import type { Dashboard } from '@js/model/dashboard'

export const dashboards = createQueryKeys('dashboards', {
  all: null,
  upNext: {
    queryKey: null,
    queryFn: () =>
      dashboardWidgetApi.fetchDashboardWidgetUpNext().then((response) => response.data),
  },
  currentWeekOverview: {
    queryKey: null,
    queryFn: () =>
      dashboardWidgetApi
        .fetchDashboardWidgetCurrentWeekOverview()
        .then((response) => response.data),
  },
  list: (params) => ({
    queryKey: [params],
    queryFn: () => fetchDashboardsByQuery(unref(params)).then((response) => response.data),
  }),
  single: (id: MaybeRefOrGetter<Dashboard['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => fetchDashboardById(unref(toRef(id))).then((response) => response.data),
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            fetchDashboardAssignedUsers(unref(toRef(id))).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            fetchDashboardInheritedAssignedUsers(unref(toRef(id))).then(
              (response) => response.data
            ),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            fetchDashboardAssignedUserGroups(unref(toRef(id))).then((response) => response.data),
        },
      },
    }
  },
})
