import axios from 'axios'
import type { AuthorizationItem } from '@js/model/authorization'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'

const basePath = '/api/authorization-items'

export function fetchByQuery(query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuthorizationItem>>(basePath, {
    params: { ...query },
  })
}

export const authorizationItemApi = {
  fetchByQuery,
}
