import invariant from 'tiny-invariant'
import { z } from 'zod'
import { taskTypes } from '@js/model/task'
import type { TaskType } from '@js/model/task'
import type { ApiResource } from '@js/types'

/**
 * Construct a union schema from an array of zod literals
 *
 * This is needed because there are some issues creating a union from readonly arrays.
 * see https://github.com/colinhacks/zod/issues/831#issuecomment-1773734131
 * @param literals
 */
function constructZodLiteralUnionType<T extends z.ZodLiteral<unknown>>(literals: Array<T>) {
  invariant(literals.length >= 2, 'The minimum length for a union is 2')
  return z.union(literals as [T, T, ...Array<T>])
}

export const rulesSchema = z
  .object({
    all: z
      .union([
        z.object({
          fact: z.union([z.literal('unit.id'), z.literal('partnerUnit.id')]),
          operator: z.literal('in'),
          value: z.array(z.number()),
        }),
        z.object({
          fact: z.literal('type'),
          operator: z.literal('in'),
          value: z.array(
            constructZodLiteralUnionType(
              Object.values(taskTypes).map((type: TaskType) => {
                return z.literal(type)
              })
            )
          ),
        }),
      ])
      .array(),
  })
  .nullable()

export const choiceTypesToResourceCollectionEndpoint = {
  AdviceType: '/api/configuration/field/advice-types',
  AssessmentType: '/api/configuration/field/assessment-types',
  BillingType: '/api/configuration/field/billing-types',
  Branch: '/api/configuration/field/branches',
  BusinessActivity: '/api/configuration/field/business-activities',
  BusinessCase: '/api/configuration/field/business-cases',
  CollateralisationType: '/api/configuration/field/collateralisation-types',
  ContractType: '/api/configuration/field/contract-types',
  DeadlineType: '/api/configuration/field/deadline-types',
  DeclarationType: '/api/configuration/field/declaration-types',
  InstrumentIdType: '/api/configuration/field/instrument-id-types',
  LineOfBusiness: '/api/configuration/field/line-of-businesses',
  LossRestriction: '/api/configuration/field/loss-restrictions',
  LossType: '/api/configuration/field/loss-types',
  PricingMethod: '/api/configuration/field/pricing-methods',
  RestrictionReason: '/api/configuration/field/restriction-reasons',
  RiskType: '/api/configuration/field/risk-types',
  ServiceType: '/api/configuration/field/service-types',
  Specification: '/api/configuration/field/specifications',
  TaxCreditType: '/api/configuration/field/tax-credit-types',
  TaxType: '/api/configuration/field/tax-types',
  TransactionType: '/api/configuration/field/transaction-types',
  TraceId: '/api/configuration/field/trace-ids',
} as const

export type ChoiceType = keyof typeof choiceTypesToResourceCollectionEndpoint
export type ChoiceCollectionEndpoint = (typeof choiceTypesToResourceCollectionEndpoint)[ChoiceType]

export type Choice = Omit<ApiResource, '@type'> & {
  '@type': ChoiceType
  id: number
  enabled: boolean
  name: string
  rules: z.infer<typeof rulesSchema>
}
