import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/configuration/TaxAccountingItemList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.item.plural'),
      },
    },
    name: 'DatasheetItemList',
    path: '/configuration/datasheets/items',
  },
  {
    component: () => import('@js/pages/configuration/ItemEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetItemEdit',
    path: '/configuration/datasheets/items/:id',
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    component: () => import('@js/pages/configuration/ItemNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetItemNew',
    path: '/configuration/datasheets/items/new',
  },
  {
    component: () => import('@js/pages/datasheet/TaxAccountingDatasheetList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.datasheet.plural'),
      },
    },
    name: 'DatasheetList',
    path: '/configuration/datasheets/sheets',
  },
  {
    component: () => import('@js/pages/datasheet/DatasheetEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetEdit',
    path: '/configuration/datasheets/sheets/:id',
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    component: () => import('@js/pages/datasheet/DatasheetNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetNew',
    path: '/configuration/datasheets/sheets/new',
  },
] as Array<RouteRecordRaw>
