<script setup lang="ts">
import { computed } from 'vue'
import { useAsyncComponent } from '@js/composable/useAsyncComponent'
import type { Icon } from '@js/utilities/name-lists'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large' | 'x-large' | 'manual'
    icon: Icon
  }>(),
  {
    size: 'medium',
  }
)

const { defineAsyncComponentWithFallback } = useAsyncComponent()
const dynamicIcon = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const variableToKeepIconReactive = props.icon
  return defineAsyncComponentWithFallback(
    () => import(`./../../assets/icons/${props.icon}.svg?component`)
  )
})
</script>

<template>
  <div
    :class="[
      { 'size-3': size === 'small' },
      { 'size-4': size === 'medium' },
      { 'size-6': size === 'large' },
      { 'size-8': size === 'x-large' },
      'inline-block fill-current',
    ]"
  >
    <component :is="dynamicIcon" role="graphics-symbol img" :aria-label="icon" />
  </div>
</template>
