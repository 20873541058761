import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { groupViewDataApi } from '@js/api/groupViewDataApi'
import { unitViewDataApi } from '@js/api/unitViewDataApi'
import { PermissionMasks } from '@js/model/permission'
import { datasheetCollectionApi } from '@js/api/datasheetCollectionApi'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { MaybeRef } from '@vueuse/core'
import type { LayoutCollectionGroupViewDataParams } from '@js/composable/useLayoutCollectionGroupViewDataQuery'
import type { LayoutCollectionUnitViewDataParams } from '@js/composable/useLayoutCollectionUnitViewDataQuery'

export const datasheetCollections = createQueryKeys('datasheetCollections', {
  all: {
    queryKey: null,
    queryFn: () =>
      datasheetCollectionApi.fetchDatasheetCollectionsByQuery({
        pagination: false,
        permission: PermissionMasks.VIEW,
      }),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      datasheetCollectionApi
        .fetchDatasheetCollectionsByQuery({
          ...unref(filters),
        })
        .then((response) => response.data),
  }),
  single: (id: MaybeRef<DatasheetCollection['id']>) => {
    return {
      queryKey: [id],
      queryFn: () =>
        datasheetCollectionApi
          .fetchDatasheetCollectionById(unref(id))
          .then((response) => response.data),
      contextQueries: {
        layouts: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchDatasheetCollectionAssignedLayouts(unref(id))
              .then((response) => response.data),
        },
        itemCountryReports: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchDatasheetCollectionAssignedItemCountryReports(unref(id))
              .then((response) => response.data),
        },
        allAssignedLayouts: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchDatasheetCollectionAssignedLayoutsForAdministrators(unref(id))
              .then((response) => response.data),
        },
      },
    }
  },
  unitViewData: (
    layoutCollectionUnitViewDataParams: MaybeRef<LayoutCollectionUnitViewDataParams>
  ) => {
    return {
      queryKey: [layoutCollectionUnitViewDataParams],
      queryFn: () => {
        const params = unref(layoutCollectionUnitViewDataParams)
        return unitViewDataApi
          .fetchDatasheetUnitViewsByQuery({
            layout: params.layoutId,
            period: params.periodId,
            unit: params.unitId,
            layoutCollection: params.layoutCollectionId,
          })
          .then((response) => response.data)
      },
    }
  },
  groupViewData: (
    layoutCollectionGroupViewDataParams: MaybeRef<LayoutCollectionGroupViewDataParams>
  ) => {
    return {
      queryKey: [layoutCollectionGroupViewDataParams],
      queryFn: () => {
        const params = unref(layoutCollectionGroupViewDataParams)
        return groupViewDataApi
          .fetchUnitHierarchyViewDataByQuery({
            layout: params.layoutId,
            period: params.periodId,
            unitHierarchy: params.unitHierarchyId,
            layoutCollection: params.layoutCollectionId,
          })
          .then((response) => response.data)
      },
    }
  },
})
