import axios from 'axios'
import type { Authorization } from '@js/model/authorization'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const authorizationApiBasePath = '/api/authorizations'

export function fetchAuthorizationAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${authorizationApiBasePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function updateAuthorizationAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${authorizationApiBasePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

export function fetchAuthorizationAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${authorizationApiBasePath}/${id}/direct-users`,
    {
      params: { pagination: false },
    }
  )
}

export function updateAuthorizationAssignedUsers(
  id: number,
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.put<HydraCollectionResponse<User>>(
    `${authorizationApiBasePath}/${id}/direct-users`,
    {
      directUsers: updatedUsers,
    }
  )
}

export function fetchAuthorizationInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${authorizationApiBasePath}/${id}/inherited-users`,
    {
      params: { pagination: false },
    }
  )
}

export function fetchAuthorizationsByQuery(query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<Authorization>>(authorizationApiBasePath, {
    params: { ...query },
  })
}

export function saveAuthorization(authorization: Partial<Authorization>) {
  if (authorization.id === undefined) {
    return axios.post<Authorization>(authorizationApiBasePath, authorization)
  }
  return axios.put<Authorization>(`${authorizationApiBasePath}/${authorization.id}`, authorization)
}

export function deleteAuthorizationById(id: Authorization['id']) {
  return axios.delete(`${authorizationApiBasePath}/${id}`)
}

export function deleteAuthorization(authorization: Authorization) {
  return axios.delete(authorization['@id'])
}

export function fetchAuthorizationById(id: Authorization['id']) {
  return axios.get<Authorization>(`${authorizationApiBasePath}/${id}`)
}
