import axios from 'axios'
import Routing from '@js/Routing'
import type { TranslationId } from '@js/translator'

export type ConfigurationDataSidebarMenu = {
  children: Array<{
    name: string
    label: TranslationId
    children: Array<{
      name: string
      label: TranslationId
      extras: {
        vueRoute: {
          name: string
        }
      }
    }>
  }>
}

export function fetchAdminAreaSidebarMenu() {
  return axios.get<ConfigurationDataSidebarMenu>(Routing.generate('u2_configurationdata_sidebar'))
}
export const configurationDataApi = {
  fetchAdminAreaSidebarMenu,
}
