import axios from 'axios'
import type { AxiosProgressEvent } from 'axios'
import type { GroupPermission, UserPermission } from '@js/model/permission'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { FileAccessType, FileEntity, FileLinkedEntity } from '@js/model/file'

export const fileApiBasePath = '/api/files'

export type FileCollectionQuery = ApiPaginationQueryParams & {
  'sort[id]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  accessType?: FileAccessType
  search?: string | Array<string>
}

export function fetchFileById(id: FileEntity['id']) {
  return axios.get<FileEntity>(`${fileApiBasePath}/${id}`)
}

export function fetchFilesByQuery(query?: FileCollectionQuery) {
  return axios.get<HydraCollectionResponse<FileEntity>>(fileApiBasePath, {
    params: { ...query },
  })
}

export function sendFilePermissionsRequest(fileId: number, comment: string) {
  return axios.post(`${fileApiBasePath}/${fileId}/request-permissions`, { comment })
}

export function updateFile(file: Partial<FileEntity>) {
  return axios.put<FileEntity>(`${fileApiBasePath}/${file.id}`, file)
}

export type FileUploadProgressEvent = {
  loaded: AxiosProgressEvent['loaded']
  total: AxiosProgressEvent['total']
}

export function createFile(
  file: Omit<Partial<FileEntity>, 'groupPermissions' | 'userPermissions'> & {
    uploadedFile: File
    userPermissions: Array<Pick<UserPermission, 'user' | 'mask'>>
    groupPermissions: Array<Pick<GroupPermission, 'group' | 'mask'>>
    linkedResource?: ApiResourceId
  },
  progressCallback?: (event: FileUploadProgressEvent) => void
) {
  const formData = new FormData()
  formData.append('uploadedFile', file.uploadedFile)
  formData.append('userPermissions', JSON.stringify(file.userPermissions))
  formData.append('groupPermissions', JSON.stringify(file.groupPermissions))
  formData.append('types', JSON.stringify(file.types))
  if (file.accessType) {
    formData.append('accessType', file.accessType)
  }
  if (file.description) {
    formData.append('description', file.description)
  }
  if (file.linkedResource) {
    formData.append('linkedResource', file.linkedResource)
  }

  return axios.post<FileEntity>(fileApiBasePath, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event) =>
      progressCallback ? progressCallback({ loaded: event.loaded, total: event.total }) : undefined,
  })
}

export function deleteFileById(id: FileEntity['id']) {
  return axios.delete(`${fileApiBasePath}/${id}`)
}

export function downloadFile(file: FileEntity) {
  return axios.get(file.downloadPath, { responseType: 'blob' })
}

export function fetchLinkedEntities(file: FileEntity) {
  return axios.get<HydraCollectionResponse<FileLinkedEntity>>(`${file['@id']}/linked-entities`)
}
