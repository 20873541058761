import axios from 'axios'
import Routing from '@js/Routing'
import type { SystemMessage } from '@js/model/system_message'
import type { HydraCollectionResponse } from '@js/types'

const basePath = '/api/system-messages'
const activeBasePath = Routing.generate('u2_systemmessagedisplay_getmessages')

export function deleteSystemMessageById(id: SystemMessage['id']) {
  return axios.delete<SystemMessage>(`/api/system-messages/${id}`)
}

export function fetchActiveSystemMessages() {
  return axios.get<Array<SystemMessage>>(activeBasePath)
}

export function fetchAllSystemMessages() {
  return axios.get<HydraCollectionResponse<SystemMessage>>(basePath)
}

export function fetchSystemMessageById(id: SystemMessage['id']) {
  return axios.get<SystemMessage>(`/api/system-messages/${id}`)
}

export function fetchSystemMessagesByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}) {
  return axios.get<HydraCollectionResponse<SystemMessage>>(basePath, {
    params: query,
  })
}

export function saveSystemMessage(systemMessage: Partial<SystemMessage>) {
  if (systemMessage.id === undefined) {
    return axios.post<SystemMessage>(basePath, systemMessage)
  }
  return axios.put<SystemMessage>(`${basePath}/${systemMessage.id}`, systemMessage)
}

export function hideSystemMessage(id: SystemMessage['id']) {
  return axios.post(Routing.generate('u2_systemmessagedisplay_hidemessage', { id }))
}
export const systemMessageApi = {
  deleteSystemMessageById,
  fetchActiveSystemMessages,
  fetchAllSystemMessages,
  fetchSystemMessageById,
  fetchSystemMessagesByQuery,
  hideSystemMessage,
  saveSystemMessage,
}
