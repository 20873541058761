import axios from 'axios'
import Routing from '@js/Routing'
import { basePath as layoutCollectionBasePath } from '@js/api/datasheetCollectionApi'
import type { AuthorizationItem } from '@js/model/authorization'
import type { MessageBag } from '@js/stores/notifications'
import type { Task, TaskParams, TaskShortName } from '@js/model/task'
import type { FilterGroup } from '@js/stores/task-list'
import type { Status } from '@js/model/status'
import type WorkflowLog from '@js/components/task/WorkflowLog.vue'
import type { Transition } from '@js/api/workflowTransitionApi'
import type { UserGroup } from '@js/model/userGroup'
import type { CheckState } from '@js/model/checkstate'
import type { PermissionMask } from '@js/model/permission'
import type {
  ApiPaginationQueryParams,
  ApiResource,
  HydraCollectionResponse,
  NewApiResource,
} from '@js/types'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { User } from '@js/model/user'

export const basePath = '/api/tasks'
export function fetchTaskDatasheetCollections(taskId: Task['id'], permission?: PermissionMask) {
  return axios.get<HydraCollectionResponse<DatasheetCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      params: { permission },
    }
  )
}

export function fetchTaskPermittedUsers(taskId: Task['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${taskId}/permitted-users`)
}

export function addDatasheetCollectionToTask(
  taskId: Task['id'],
  layoutCollectionId: DatasheetCollection['id']
) {
  return axios.post<HydraCollectionResponse<DatasheetCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      layoutCollection: `${layoutCollectionBasePath}/${layoutCollectionId}`,
    }
  )
}

export function removeDatasheetCollectionsFromTask(
  taskId: Task['id'],
  layoutCollectionId: DatasheetCollection['id']
) {
  return axios.delete<HydraCollectionResponse<DatasheetCollection>>(
    `${basePath}/${taskId}/layout-collections/` + layoutCollectionId
  )
}

export function fetchTaskById(taskId: Task['id']) {
  return axios.get<Task>(`${basePath}/${taskId}`)
}

export type TaskCheckStateCollectionQuery = ApiPaginationQueryParams & {
  current?: boolean
  check?: string | Array<string>
}
export function fetchTaskCheckStatesByQuery(
  taskId: Task['id'],
  query: TaskCheckStateCollectionQuery
) {
  return axios.get<HydraCollectionResponse<CheckState>>(`${basePath}/${taskId}/check-states`, {
    params: { ...query },
  })
}

type UserAssignData = {
  assignee: User['@id'] | null
  userGroup: UserGroup['@id'] | null
  commentContent: string | null
}
export function assignTaskToUser(task: Task, userAssignData: UserAssignData) {
  return axios.post(`${basePath}/${task.id}/assign-user`, userAssignData)
}

export function watchTask(task: Task) {
  return axios.get(`${task['u2:extra'].watchPath}`)
}

export function unwatchTask(task: Task) {
  return axios.get(`${task['u2:extra'].unwatchPath}`)
}

type ReviewComment = {
  userGroup: UserGroup['@id'] | null
  commentContent?: string
}

export function addTaskReview(task: Task, comment: ReviewComment) {
  return axios.post(`${task['@id']}/add-review`, comment)
}

export function removeTaskReview(task: Task, comment: ReviewComment) {
  return axios.post(`${task['@id']}/remove-review`, comment)
}

type TransitionData = {
  transition: Transition['@id']
  assignee: User['@id'] | null
  userGroup: UserGroup['@id'] | null
  comment?: string
}

export function transitionTask(task: Task, transitionData: TransitionData) {
  return axios.post(`${task['@id']}/transition`, transitionData)
}

export type WorkflowLog = ApiResource & {
  id: number
  time: string
  username: string
  userId: number
  originStatusName: string
  originStatusType: Status['type']
  destinationStatusName: string
  destinationStatusType: Status['type']
}
export function fetchWorkflowLogs(task: Task) {
  return axios.get<HydraCollectionResponse<WorkflowLog>>(`${task['@id']}/status-transition-logs`)
}

interface ParseUqlResult {
  valid: boolean
  error: string
  compatible: boolean
  filters: FilterGroup
}
export function evaluateTaskUql(uql: string, taskShortName: TaskShortName) {
  return axios.post<ParseUqlResult>(
    Routing.generate('u2_tasktype_parseUql', { shortName: taskShortName }),
    { uql }
  )
}

export function bulkDeleteTasks(selection: string, taskShortName: TaskShortName) {
  return axios.delete(
    Routing.generate('u2_bulk_delete', {
      shortName: taskShortName,
      selection,
    })
  )
}

export function fetchTaskFields() {
  return axios.get<Record<string, Array<string>>>(Routing.generate('u2_task_fields'))
}

export function deleteTaskByShortNameAndId(taskParams: TaskParams) {
  return axios.delete(Routing.generate('u2_tasktype_delete', taskParams))
}

export function addTaskCheckState(checkStates: NewApiResource<CheckState>) {
  return axios.post('/api/task-check-states', checkStates)
}

export function fetchTaskEditForm(taskParams: TaskParams) {
  return axios.get<{ html: string; disabled: boolean }>(
    Routing.generate('u2_tasktype_editform', taskParams)
  )
}

export function fetchTaskNewForm(shortName: TaskShortName, transactionType?: string) {
  return axios.get<{ html: string; disabled: boolean }>(
    Routing.generate('u2_tasktype_newform', { shortName }),
    { params: { type: transactionType } }
  )
}

export function fetchTaskBulkEditForm(shortName: TaskShortName, selection: string) {
  return axios.get<{
    html: string
    disabled: boolean
    authorisationItem: AuthorizationItem
    itemPluralName: string
    recordCount: number
    listPath: string
    newPath: string
    formName: string
  }>(
    Routing.generate('u2_bulk_editform', {
      shortName,
      selection,
    })
  )
}

export function duplicateTask(id: Task['id']) {
  return axios.post<{ redirect: string; messages: MessageBag }>(
    Routing.generate('u2_tasktype_duplicate', { id })
  )
}

export function createTask(shortName: TaskShortName, formData: FormData, transactionType?: string) {
  return axios.post<{ redirect: string; messages: MessageBag }>(
    Routing.generate('u2_tasktype_submitnewform', { shortName }),
    formData,
    {
      params: { type: transactionType },
    }
  )
}

export function updateTask(payload: {
  shortName: TaskShortName
  id: Task['u2:extra']['taskTypeId']
  formData: FormData
}) {
  return axios.post<{ html: string; redirectUrl: string; messages: MessageBag; disabled: boolean }>(
    Routing.generate('u2_tasktype_submiteditform', {
      shortName: payload.shortName,
      id: payload.id,
    }),
    payload.formData
  )
}

export function bulkEditTask(payload: { shortName: TaskShortName; formData: FormData }) {
  return axios.post<{ messages: MessageBag }>(
    Routing.generate('u2_bulk_submiteditform', {
      shortName: payload.shortName,
    }),
    payload.formData
  )
}

export function fetchTaskBulkTransitionForm(payload: {
  shortName: TaskShortName
  selection: string
}) {
  return axios.get<string>(
    Routing.generate('u2_bulktransition_prepare', {
      selection: payload.selection,
      shortName: payload.shortName,
    })
  )
}

export function bulkTransitionTask(payload: {
  shortName: TaskShortName
  selection: string
  formData: FormData
}) {
  return axios.post<{ redirect: string; messages: MessageBag }>(
    Routing.generate('u2_bulktransition_transition', {
      selection: payload.selection,
      shortName: payload.shortName,
    }),
    payload.formData
  )
}
