import { acceptHMRUpdate, defineStore } from 'pinia'
import { StatusCodes } from 'http-status-codes'
import { computed, ref } from 'vue'
import * as UserGroupApi from '@js/api/userGroupApi'
import type { ApiResourceId } from '@js/types'
import type { UserGroup } from '@js/model/userGroup'

export const useUserGroupStore = defineStore('user-group', () => {
  const userGroups = ref<Array<UserGroup>>([])
  async function fetchAllUserGroups() {
    const { data } = await UserGroupApi.fetchAllUserGroups()
    userGroups.value = data['hydra:member']
  }
  async function fetchUserGroupById(id: NonNullable<UserGroup['id']>) {
    const { data } = await UserGroupApi.fetchUserGroupById(id)
    userGroups.value.push(data)
  }
  async function deleteUserGroup(id: NonNullable<UserGroup['id']>) {
    try {
      const response = await UserGroupApi.deleteUserGroupById(id)
      if (response.status === StatusCodes.NO_CONTENT) {
        userGroups.value = userGroups.value.filter((group: UserGroup) => group.id !== id)
      }
    } catch {
      // handled by axios interceptor
    }
  }

  const getUserGroupByIri = computed(() => {
    return (userGroupIri: ApiResourceId) =>
      userGroups.value.find((group) => group['@id'] === userGroupIri)
  })

  const getUserGroupById = computed(() => {
    return (userGroupId: number) => userGroups.value.find((group) => group.id === userGroupId)
  })

  return {
    userGroups,
    fetchAllUserGroups,
    fetchUserGroupById,
    deleteUserGroup,
    getUserGroupByIri,
    getUserGroupById,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserGroupStore, import.meta.hot))
}
