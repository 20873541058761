import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import * as StatusApi from '@js/api/statusApi'
import type { MaybeRefOrGetter } from 'vue'
import type { Status } from '@js/model/status'

export const statuses = createQueryKeys('statuses', {
  all: {
    queryKey: null,
    queryFn: () => StatusApi.fetchAllStatuses().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      StatusApi.fetchStatusesByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRefOrGetter<Status['id'] | undefined>) => ({
    queryKey: [id],
    queryFn: () => {
      const value = toValue(id)
      invariant(value)
      return StatusApi.fetchStatusById(value).then((response) => response.data)
    },
  }),
})
