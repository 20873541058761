import { useQueryClient } from '@tanstack/vue-query'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import { useDatasheetParametersStore } from '@js/stores/datasheet-parameters'
import { datasheetCollectionApi } from '@js/api/datasheetCollectionApi'
import { queries } from '@js/query'
import { useNotificationsStore } from '@js/stores/notifications'
import type { SelectedView } from '@js/stores/datasheet-parameters'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { Datasheet, Field } from '@js/model/datasheet'
import type { Unit } from '@js/model/unit'
import type { Period } from '@js/model/period'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'

export default [
  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetCollectionEdit',
    path: '/configuration/datasheets/collections/:id',
    props: (route: RouteLocation) => ({
      id: route.params.id,
    }),
  },

  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetCollectionNew',
    path: '/configuration/datasheets/collections/new',
  },
  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.datasheet_collection.plural'),
      },
    },
    name: 'DatasheetCollectionList',
    path: '/configuration/datasheets/collections',
  },

  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionGroupViewBreakdown.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'DatasheetCollectionGroupViewBreakdown',
    path: '/datasheets/breakdown/unit-hierarchy/by-unit',
  },
  {
    component: () => import('@js/pages/datasheet/ItemCountryReport.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'ItemCountryReport',
    path: '/datasheets/breakdown/unit-hierarchy/by-country',
  },

  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionSheetView.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'DatasheetCollectionSheetView',
    path: '/datasheets/collections/:id/sheets/:sheetId',
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
      layout: to.meta.props?.selectedLayout,
    }),
    beforeEnter: async (to) => {
      const layoutParamStore = useDatasheetParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)

      if (to.params.sheetId) {
        layoutParamStore.parameters.layout = Number(to.params.sheetId)
      }

      layoutParamStore.updateFromLocationQuery(to.query)

      const assignedLayouts = await useQueryClient()
        .ensureQueryData(
          queries.datasheetCollections.single(layoutParamStore.parameters.layoutCollection)._ctx
            .layouts
        )
        .then(({ 'hydra:member': layouts }) => layouts)

      const layout = assignedLayouts.find((layout) => layout.id === +to.params.sheetId)
      if (layout === undefined) {
        useNotificationsStore().addError(
          Translator.trans('u2.datasheet_collection.datasheet_no_found_or_missing_permission')
        )

        return buildNoLayoutSelectedRoute()
      }

      const { data: layoutCollection } = await datasheetCollectionApi.fetchDatasheetCollectionById(
        layoutParamStore.parameters.layoutCollection
      )
      to.meta.props = { layoutCollection, selectedLayout: layout }
    },
  },
  {
    component: () => import('@js/pages/datasheet/DatasheetCollectionNoLayoutSelected.vue'),
    name: 'DatasheetCollectionNoSheetSelected',
    path: '/datasheets/collections/:id',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
    }),
    beforeEnter: async (to: RouteLocation) => {
      const layoutParamStore = useDatasheetParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = undefined

      layoutParamStore.updateFromLocationQuery(to.query)

      const { data: layoutCollection } = await datasheetCollectionApi.fetchDatasheetCollectionById(
        layoutParamStore.parameters.layoutCollection
      )

      to.meta.props = { layoutCollection }
    },
  },
] as Array<RouteRecordRaw>

export type DatasheetRouteParameters = {
  layoutCollectionId: DatasheetCollection['id']
  layoutId?: Datasheet['id']
  fieldId?: Field['id']
  unitId?: Unit['id'] | '' // Allow empty string to keep the parameter in the URL. `null` or `undefined` would cause the parameter to be removed.
  periodId?: Period['id']
  hierarchyId?: UnitHierarchy['id'] | '' // Allow empty string to keep the parameter in the URL. `null` or `undefined` would cause the parameter to be removed.
}

function resolveView(parmeters: DatasheetRouteParameters) {
  if ('unitId' in parmeters && parmeters.unitId) {
    return 'unit'
  }

  return 'hierarchyId' in parmeters ? 'group' : 'unit'
}

export const buildDatasheetRoute = (parameters: DatasheetRouteParameters) => {
  const layoutParamStore = useDatasheetParametersStore()

  const queryParameters = {
    field: parameters.fieldId ?? layoutParamStore.parameters.field,
    period: parameters.periodId ?? layoutParamStore.parameters.period,
  } as {
    period: Period['id']
    unit?: Unit['id'] | ''
    unitHierarchy?: UnitHierarchy['id'] | ''
    field?: Field['id']
  }

  const view = resolveView(parameters)
  if (view === 'unit') {
    queryParameters.unit = parameters.unitId ?? layoutParamStore.parameters.unit
  }

  if (view === 'group') {
    queryParameters.unitHierarchy =
      parameters.hierarchyId ?? layoutParamStore.parameters.unitHierarchy
  }

  if (parameters.layoutId === undefined) {
    return {
      name: 'DatasheetCollectionNoSheetSelected',
      params: {
        id: parameters.layoutCollectionId,
      },
      query: queryParameters,
    }
  }

  return {
    name: 'DatasheetCollectionSheetView',
    params: {
      id: parameters.layoutCollectionId,
      sheetId: parameters.layoutId,
    },
    query: queryParameters,
  }
}

export const buildNoLayoutSelectedRoute = (view?: SelectedView) => {
  const layoutParamStore = useDatasheetParametersStore()
  const queryParameters = {
    period: layoutParamStore.parameters.period,
  } as {
    period: Period['id']
    unit?: Unit['id'] | ''
    unitHierarchy?: UnitHierarchy['id'] | ''
    field?: Field['id']
  }

  if ((view ?? layoutParamStore.parameters.selectedView) === 'unit') {
    queryParameters.unit = layoutParamStore.parameters.unit
  }

  if ((view ?? layoutParamStore.parameters.selectedView) === 'group') {
    queryParameters.unitHierarchy = layoutParamStore.parameters.unitHierarchy
  }

  return {
    name: 'DatasheetCollectionNoSheetSelected',
    params: {
      id: layoutParamStore.parameters.layoutCollection,
    },
    query: queryParameters,
  }
}
