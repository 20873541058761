import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import * as TaskApi from '@js/api/taskApi'
import type { MaybeRef } from '@vueuse/core'
import type { Task } from '@js/model/task'
import type { PermissionMask } from '@js/model/permission'

export const tasks = createQueryKeys('tasks', {
  single: (id) => {
    return {
      queryKey: [id],
      queryFn: () => TaskApi.fetchTaskById(unref(id)).then((response) => response.data),
      contextQueries: {
        layoutCollections: (permission?: PermissionMask) => {
          return {
            queryKey: [permission],
            queryFn: () =>
              TaskApi.fetchTaskDatasheetCollections(unref(id), unref(permission)).then(
                (response) => response.data
              ),
          }
        },
        permittedUsers: {
          queryKey: ['permitted-users'],
          queryFn: () =>
            TaskApi.fetchTaskPermittedUsers(unref(id)).then((response) => response.data),
        },
      },
    }
  },
  workflowLog: (task: MaybeRef<Task>) => {
    const taskId = unref(task).id
    return {
      queryKey: [taskId],
      queryFn: () => TaskApi.fetchWorkflowLogs(toValue(task)).then((response) => response.data),
    }
  },
})
