import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import * as DocumentApi from '@js/api/documentApi'
import type { DocumentSection } from '@js/model/document'
import type { TaskParams } from '@js/model/task'
import type { MaybeRef } from '@vueuse/core'

export const document = createQueryKeys('document', {
  editDocumentData: (taskParams: MaybeRef<TaskParams>) => {
    return {
      queryKey: [taskParams],
      queryFn: () => {
        const params = unref(taskParams)
        return DocumentApi.fetchDocumentByTask(params.shortName, params.id).then(
          (response) => response.data
        )
      },
      contextQueries: {
        sections: {
          queryKey: null,
          queryFn: () => {
            const params = unref(taskParams)
            return DocumentApi.fetchDocumentRenderedSections(params.shortName, params.id).then(
              (response) => response.data
            )
          },
        },
      },
    }
  },
  sections: (section: MaybeRef<DocumentSection>) => {
    const params = unref(section)
    return {
      queryKey: ['sectionInformation', params.id, params.updatedAt],
      queryFn: () => {
        return DocumentApi.fetchDocumentSectionInformation(params).then((response) => response.data)
      },
    }
  },
})
