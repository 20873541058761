import axios from 'axios'
import type { HydraCollectionResponse, NewApiResource } from '@js/types'
import type {
  UnitHierarchy,
  UnitHierarchyStructure,
  UnitHierarchyStructureApiFormat,
} from '@js/model/unit_hierarchy'

export const unitHierarchyApiBasePath = '/api/unit-hierarchies'

export function fetchUnitHierarchyById(id: UnitHierarchy['id']) {
  return axios.get<UnitHierarchy>(`${unitHierarchyApiBasePath}/${id}`)
}

export function fetchUnitHierarchiesByQuery(query: {
  page?: number
  pagination?: boolean
  itemsPerPage?: number
}) {
  return axios.get<HydraCollectionResponse<UnitHierarchy>>(unitHierarchyApiBasePath, {
    params: { ...query },
  })
}
export function fetchAllUnitHierarchies() {
  return axios.get<HydraCollectionResponse<UnitHierarchy>>(unitHierarchyApiBasePath, {
    params: { pagination: false },
  })
}

export function createUnitHierarchy(payload: NewApiResource<UnitHierarchy>) {
  return axios.post<UnitHierarchy>(`${unitHierarchyApiBasePath}`, payload)
}

export function updateUnitHierarchy(payload: UnitHierarchy) {
  return axios.put<UnitHierarchy>(payload['@id'], payload)
}

export function updateUnitHierarchyStructure(payload: UnitHierarchyStructureApiFormat) {
  return axios.put<UnitHierarchyStructure>(payload['@id'], payload)
}

export function fetchUnitHierarchyStructure(id: UnitHierarchy['id'], date: string) {
  return axios.get<UnitHierarchyStructure>(`${unitHierarchyApiBasePath}/${id}/structure/${date}`)
}

export function deleteUnitHierarchyById(id: UnitHierarchy['id']) {
  return axios.delete(`${unitHierarchyApiBasePath}/${id}`)
}
