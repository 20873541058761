<script setup lang="ts">
import { computed } from 'vue'
import ButtonBasic from '@js/components/buttons/ButtonBasic.vue'
import { useNotificationsStore } from '@js/stores/notifications'
import AppNotification from '@js/components/notifications/AppNotification.vue'

const store = useNotificationsStore()
const notifications = computed(() => store.notifications)
const clear = () => store.clear()
</script>

<template>
  <teleport to="#teleport-target-notifications">
    <transition
      enter-active-class="ease-out duration-200"
      leave-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      name="fade"
    >
      <div v-show="notifications.length > 0" id="app-notifications">
        <div class="pointer-events-none fixed inset-x-0 left-0 top-0 z-50 mt-2 flex justify-center">
          <div
            class="pointer-events-auto relative w-11/12 max-w-md rounded-sm border border-solid border-gray-200 bg-white shadow-lg"
          >
            <ButtonBasic
              icon="cross"
              class="absolute right-0 top-0 mr-1 mt-1 text-blue-600"
              @click="clear()"
            />
            <transition-group
              enter-active-class="ease-out duration-300"
              leave-active-class="ease-in duration-300"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
              name="fade"
            >
              <template v-for="(notification, index) in notifications" :key="index">
                <AppNotification
                  ref="notification"
                  :type="notification.type"
                  :content="notification.message"
                />
              </template>
            </transition-group>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
