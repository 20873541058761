import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import { auditorApi } from '@js/api/auditorApi'
import type { MaybeRef } from '@vueuse/core'
import type { Auditor } from '@js/model/auditor'

export const auditors = createQueryKeys('auditors', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      auditorApi.fetchAuditorByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRef<Auditor['id'] | undefined>) => {
    const value = toValue(id)
    invariant(value !== undefined, 'id is required')
    return {
      queryKey: [id],
      queryFn: () => auditorApi.fetchAuditorById(value).then((response) => response.data),
    }
  },
})
