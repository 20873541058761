import axios from 'axios'
import type { Datasheet, ItemUnitHierarchyValue, LayoutItem } from '@js/model/datasheet'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { Period } from '@js/model/period'
import type { MaybeRef } from '@vueuse/core'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'

const basePath = '/api/item-unit-hierarchy-values'

export type ItemUnitHierarchyValueCollectionQuery = ApiPaginationQueryParams & {
  datasheet?: Datasheet['id']
  unitHierarchy: UnitHierarchy['id']
  period: Period['id']
}
export function buildId(
  itemId: MaybeRef<LayoutItem['id']>,
  unitHierarchyId: MaybeRef<UnitHierarchy['id']>,
  periodId: MaybeRef<Period['id']>
) {
  return `${itemId}-${unitHierarchyId}-${periodId}`
}

export function fetchItemUnitHierarchyValueById(
  itemId: MaybeRef<LayoutItem['id']>,
  unitHierarchyId: MaybeRef<UnitHierarchy['id']>,
  periodId: MaybeRef<Period['id']>
) {
  return axios.get<ItemUnitHierarchyValue>(
    `${basePath}/${buildId(itemId, unitHierarchyId, periodId)}`
  )
}

export function fetchItemUnitHierarchyValuesByQuery(query: ItemUnitHierarchyValueCollectionQuery) {
  return axios.get<HydraCollectionResponse<ItemUnitHierarchyValue>>(basePath, {
    params: { ...query },
  })
}

export const itemUnitHierarchyValueApi = {
  fetchItemUnitHierarchyValueById,
  fetchItemUnitHierarchyValuesByQuery,
  basePath,
  buildId,
}
