import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import invariant from 'tiny-invariant'
import * as UserGroupApi from '@js/api/userGroupApi'
import type { MaybeRefOrGetter } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import type { UserGroup } from '@js/model/userGroup'

export const userGroups = createQueryKeys('userGroups', {
  all: {
    queryKey: null,
    queryFn: () => UserGroupApi.fetchAllUserGroups().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      UserGroupApi.fetchUserGroupsByQuery({ ...toValue(filters) }).then(
        (response) => response.data
      ),
  }),
  single: (id: MaybeRefOrGetter<UserGroup['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = toValue(id)
        invariant(idValue)
        return UserGroupApi.fetchUserGroupById(idValue).then((response) => response.data)
      },
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return UserGroupApi.fetchUserGroupAssignedUsers(idValue).then(
              (response) => response.data
            )
          },
        },
        roles: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return UserGroupApi.fetchUserGroupAssignedRoles(idValue).then(
              (response) => response.data
            )
          },
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return UserGroupApi.fetchUserGroupInheritedRoles(idValue).then(
              (response) => response.data
            )
          },
        },
        units: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return UserGroupApi.fetchUserGroupAssignedUnits(idValue).then(
              (response) => response.data
            )
          },
        },
      },
    }
  },
  rights: (userGroup: MaybeRef<UserGroup>) => {
    const userGroupId = unref(userGroup).id
    return {
      queryKey: [userGroupId],
      queryFn: () =>
        UserGroupApi.fetchUserGroupRights(toValue(userGroup)).then((response) => response.data),
    }
  },
})
