import axios from 'axios'
import type { AuthorizationProfile } from '@js/model/authorization'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const authorizationProfileApiBasePath = '/api/authorization-profiles'

export function fetchAuthorizationProfileAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(
    `${authorizationProfileApiBasePath}/${id}/groups`,
    { params: { pagination: false } }
  )
}

export function updateAuthorizationProfileAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.put<HydraCollectionResponse<UserGroup>>(
    `${authorizationProfileApiBasePath}/${id}/groups`,
    { groups: updatedGroups }
  )
}

export function fetchAuthorizationProfileAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${authorizationProfileApiBasePath}/${id}/direct-users`,
    { params: { pagination: false } }
  )
}

export function updateAuthorizationProfileAssignedUsers(
  id: number,
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.put<HydraCollectionResponse<User>>(
    `${authorizationProfileApiBasePath}/${id}/direct-users`,
    { directUsers: updatedUsers }
  )
}

export function fetchAuthorizationProfileInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${authorizationProfileApiBasePath}/${id}/inherited-users`,
    { params: { pagination: false } }
  )
}

export function saveAuthorizationProfile(authorizationProfile: Partial<AuthorizationProfile>) {
  if (authorizationProfile.id === undefined) {
    return axios.post<AuthorizationProfile>(authorizationProfileApiBasePath, authorizationProfile)
  }
  return axios.put<AuthorizationProfile>(
    `${authorizationProfileApiBasePath}/${authorizationProfile.id}`,
    authorizationProfile
  )
}

export function deleteAuthorizationProfile(authorizationProfile: AuthorizationProfile) {
  return axios.delete(`${authorizationProfile['@id']}`)
}

export function deleteAuthorizationProfileById(id: AuthorizationProfile['id']) {
  return axios.delete(`${authorizationProfileApiBasePath}/${id}`)
}

export function fetchAuthorizationProfilesByQuery(query: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuthorizationProfile>>(authorizationProfileApiBasePath, {
    params: { ...query },
  })
}

export function fetchAuthorizationProfileById(id: AuthorizationProfile['id']) {
  return axios.get<AuthorizationProfile>(`${authorizationProfileApiBasePath}/${id}`)
}
