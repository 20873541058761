import axios from 'axios'
import Routing from '@js/Routing'
import type { MessageBag } from '@js/stores/notifications'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { Datasheet, UnitViewData } from '@js/model/datasheet'
import type { Period } from '@js/model/period'
import type { Unit } from '@js/model/unit'

export function fetchDatasheetUnitViewsByQuery(query: {
  period: Period['id']
  unit: Unit['id']
  layout: Datasheet['id']
  layoutCollection: DatasheetCollection['id']
}) {
  return axios.get<UnitViewData>(Routing.generate('u2_layout_unitviewdata'), {
    params: {
      ...query,
    },
  })
}

export function updateDatasheetUnitView(
  query: {
    period: Period['id']
    unit: Unit['id']
    layout: Datasheet['id']
    layoutCollection: DatasheetCollection['id']
  },
  formData: FormData
) {
  return axios.post<{ messages: Record<string, Array<string>>; html: string }>(
    Routing.generate('u2_layout_unitviewsubmit', {
      ...query,
    }),
    formData
  )
}

export function downloadDatasheetUnitViewXls(params: {
  period: Period['id']
  unit: Unit['id']
  datasheet: Datasheet['id']
}) {
  return axios.get<Blob>(Routing.generate('u2_datasheets_unitview_export', params), {
    responseType: 'blob',
  })
}

export function recalculateDatasheetUnitPeriod(params: { unit: Unit['id']; period: Period['id'] }) {
  return axios.post<{ messages: MessageBag }>(Routing.generate('u2_unitperiod_recalculate', params))
}
export const unitViewDataApi = {
  downloadDatasheetUnitViewXls,
  fetchDatasheetUnitViewsByQuery,
  recalculateDatasheetUnitPeriod,
  updateDatasheetUnitView,
}
