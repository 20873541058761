import * as FileTypeApi from '@js/api/fileTypeApi'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'

export const fileTypes = createQueryKeys('fileTypes', {
  all: {
    queryKey: null,
    queryFn: () => FileTypeApi.fetchAllFileTypes().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      FileTypeApi.fetchFileTypeByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
})
