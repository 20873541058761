import axios from 'axios'
import type { AuditLog } from '@js/model/auditLog'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { Period } from '@js/model/period'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const periodApiBasePath = '/api/periods'

export type PeriodCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[closed]'?: SortingDirection
  'sort[endDate]'?: SortingDirection
  'sort[name]'?: SortingDirection
  'sort[previousPeriod.name]'?: SortingDirection
  'sort[startDate]'?: SortingDirection
  'sort[description]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchPeriodsByQuery(query?: PeriodCollectionQuery) {
  return axios.get<HydraCollectionResponse<Period>>(periodApiBasePath, {
    params: { ...query },
  })
}
export function fetchAllPeriods() {
  return fetchPeriodsByQuery({ pagination: false })
}

export function fetchPeriodById(id: number) {
  return axios.get<Period>(`${periodApiBasePath}/${id}`)
}

export function fetchPeriodByIri(iri: string) {
  return axios.get<Period>(iri)
}

export function deletePeriod(id: number) {
  return axios.delete<Period>(`${periodApiBasePath}/${id}`)
}

export function savePeriod(period: Partial<Period>) {
  if (period.id === undefined) {
    return axios.post<Period>(periodApiBasePath, period)
  }
  return axios.put<Period>(`${periodApiBasePath}/${period.id}`, period)
}

export function fetchPeriodLogsByQuery(id: Period['id'], query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuditLog>>(`${periodApiBasePath}/${id}/logs`, {
    params: { ...query },
  })
}
