import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  replayIntegration,
  vueIntegration,
} from '@sentry/vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import { VueQueryPlugin } from '@tanstack/vue-query'
import FloatingVue from 'floating-vue'
import { useAuthStore } from '@js/stores/auth'
import queryClient from '@js/queryClient'
import router from '@js/router'
import TheApp from '@js/App.vue'
import '@js/zodErrorMap'

export default async function () {
  const app = createApp(TheApp)

  const head = createHead()
  const pinia = createPinia()
  // TODO: investigate the sentry pinia plugin and enable when sensitive state has been identified and ignored
  //pinia.use(createSentryPiniaPlugin())

  await useAuthStore(pinia).resume()

  app.use(pinia)
  app.use(router)
  app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true })
  app.use(FloatingVue, {
    themes: {
      'context-menu': {
        $extend: 'menu',
      },
    },
  })
  app.use(head)
  function getIsSentryEnabled() {
    return window.location.hostname.includes('.universalunits.app')
  }

  function getEnvironment() {
    if (window.location.hostname.includes('.test.universalunits.app')) {
      return 'test'
    }
    if (window.location.hostname.includes('.stage.universalunits.app')) {
      return 'stage'
    }
    return 'production'
  }

  init({
    app,
    dsn: 'https://fe585c247ac2444495e1eec6d411d13d@o514362.ingest.sentry.io/5617521',
    beforeSend(event) {
      const exceptionValues = event.exception?.values
      if (exceptionValues) {
        const frames = exceptionValues[0].stacktrace?.frames ?? []
        for (const frame of frames) {
          // Filter out events that contain 'gtag' which is an error with Google tag manager
          if (frame.filename?.includes('gtag')) {
            return null
          }
        }
      }

      return event
    },
    denyUrls: ['localhost'],
    enabled: getIsSentryEnabled(),
    environment: getEnvironment(),
    // list of URLs to capture for tracing
    tracePropagationTargets: [/localhost/, /universalunits\.app\/(api|legacy)/, /\/(api|legacy)/],
    integrations: [
      browserProfilingIntegration(),
      browserTracingIntegration({ router }),
      replayIntegration(),
      vueIntegration({
        // trackComponents: true,
        // hooks: ['activate', 'create', 'destroy', 'mount', 'update'], // or use default hooks ['activate', 'mount', 'update']
      }),
    ],
    ignoreErrors: [
      'Request failed with status code 421',
      'error loading dynamically imported module',
      'Failed to fetch dynamically imported module',
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    release: 'universalunits@' + process.env.VERSION,
    // 100% - Sample rate is suggested by the dynamic sampling. See
    // https://docs.sentry.io/product/data-management-settings/dynamic-sampling/getting-started/#4-increase-your-sdk-transaction-sample-rate
    tracesSampleRate: 0.5,
    profilesSampleRate: 0.1,
  })

  app.mount('#app')

  return app
}
