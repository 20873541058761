import axios from 'axios'
import Routing from '@js/Routing'
import type { CalendarEntry } from '@js/model/calendar'

export function fetchDashboardWidgetUpNext() {
  return axios.get<Record<string, Array<CalendarEntry>>>(Routing.generate('u2_calendar_upnext'))
}

export function fetchDashboardWidgetCurrentWeekOverview() {
  return axios.get<{ entries: Record<string, Array<CalendarEntry>> }>(
    Routing.generate('u2_calendar_currentweek')
  )
}

export const dashboardWidgetApi = {
  fetchDashboardWidgetUpNext,
  fetchDashboardWidgetCurrentWeekOverview,
}
