import { ZodIssueCode, z } from 'zod'
import { defaultValidationMessages } from '@js/helper/form/validation-messages'

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (
        (issue.expected === 'number' || issue.expected === 'string') &&
        (ctx.data === null || ctx.data === undefined)
      ) {
        return { message: defaultValidationMessages.required() }
      }

      break
    case ZodIssueCode.too_small:
      if (issue.type === 'string') {
        if (issue.minimum === 1 && ctx.data === '') {
          return { message: defaultValidationMessages.required() }
        }
        if (issue.exact && issue.minimum > 1) {
          return { message: defaultValidationMessages.exactLength(issue.minimum) }
        }
        return { message: defaultValidationMessages.minLength(issue.minimum) }
      }
      break
    case ZodIssueCode.too_big:
      if (issue.type === 'string') {
        if (issue.exact) {
          return { message: defaultValidationMessages.exactLength(issue.maximum) }
        }
        return { message: defaultValidationMessages.maxLength(issue.maximum) }
      }
      break
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)
