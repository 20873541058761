import { fetchUserAssignedDashboards } from '@js/api/userApi'
import { useQueryClient } from '@tanstack/vue-query'
import { useLocalStorage } from '@vueuse/core'
import { StatusCodes } from 'http-status-codes'
import invariant from 'tiny-invariant'
import { isAxiosError } from 'axios'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import { queries } from '@js/query'
import { useAuthStore } from '@js/stores/auth'
import * as DashboardApi from '@js/api/dashboardApi'
import type { Dashboard } from '@js/model/dashboard'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/AppDashboard.vue'),
    name: 'AppDashboard',
    path: '/dashboard/:slug/:id',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({ dashboard: to.meta.dashboard }),
    beforeEnter: async (to: RouteLocation) => {
      const dashboardId = to.params.id
      invariant(typeof dashboardId === 'string', 'Expected a string')
      const queryClient = useQueryClient()

      const dashboard = await queryClient.ensureQueryData({
        queryKey: queries.dashboards.single(dashboardId).queryKey,
        queryFn: () =>
          DashboardApi.fetchDashboardById(dashboardId)
            .then((response) => response.data)
            .catch((error) => {
              // Ignore 404. We will redirect to AppDefaultDashboard
              if (!isAxiosError(error) || error.response?.status !== 404) {
                throw error
              }
              return null
            }),
      })

      const currentUserId = useAuthStore().user?.id
      invariant(typeof currentUserId === 'number', 'Expected a string')
      const lastUsedDashboardId = useLocalStorage<Dashboard['id']>(
        'dashboard:last:' + currentUserId,
        null
      )

      if (!dashboard) {
        lastUsedDashboardId.value = null
        return {
          name: 'AppDefaultDashboard',
        }
      }

      lastUsedDashboardId.value = dashboard['id']

      to.meta.dashboard = dashboard
    },
  },
  {
    component: () => import('@js/pages/AppDashboardNotFound.vue'),
    name: 'Error404Dashboard',
    path: '/dashboard-:slug',
    alias: '/dashboard/:slug',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({ dashboard: to.meta.dashboard }),
  },
  {
    component: () => import('@js/pages/AppDashboardNotFound.vue'),
    name: 'AppDefaultDashboard',
    path: '/dashboard',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({ dashboard: to.meta.dashboard }),
    beforeEnter: async () => {
      const authStore = useAuthStore()
      invariant(authStore.user)
      const lastUsedDashboardId = useLocalStorage<Dashboard['id']>(
        'dashboard:last:' + authStore.user.id,
        null
      )

      const queryClient = useQueryClient()
      if (lastUsedDashboardId.value) {
        const lastDashboard = await queryClient
          .ensureQueryData(queries.dashboards.single(lastUsedDashboardId.value))
          .catch((error) => {
            // Ignore 404. We will redirect to AppDefaultDashboard
            if (isAxiosError(error) && error.response?.status === StatusCodes.NOT_FOUND) {
              return
            }
            throw error
          })

        if (lastDashboard) {
          return {
            name: 'AppDashboard',
            params: { slug: lastDashboard.slug, id: lastDashboard.id },
          }
        }
      }

      const currentUserId = useAuthStore().user?.id
      invariant(typeof currentUserId === 'number', 'Expected a string')
      const { data } = await queryClient.ensureQueryData({
        queryKey: queries.users.single(currentUserId)._ctx.dashboards.queryKey,
        queryFn: () => fetchUserAssignedDashboards(currentUserId),
      })

      if (data['hydra:member'].length === 0) {
        lastUsedDashboardId.value = null
        return
      }

      const firstDashboardInList = data['hydra:member'][0]
      lastUsedDashboardId.value = firstDashboardInList['id']

      return {
        name: 'AppDashboard',
        params: { slug: firstDashboardInList.slug, id: firstDashboardInList.id },
      }
    },
  },
  {
    component: () => import('@js/pages/configuration/DashboardEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DashboardEdit',
    path: '/configuration/dashboard/:id/edit',
    props: (to: RouteLocation) => ({ dashboard: to.meta.dashboard }),
    beforeEnter: async (to: RouteLocation) => {
      const dashboardId = to.params.id
      invariant(typeof dashboardId === 'string', 'Expected a string')
      const queryClient = useQueryClient()

      const dashboard = await queryClient.ensureQueryData({
        queryKey: queries.dashboards.single(dashboardId).queryKey,
        queryFn: () =>
          DashboardApi.fetchDashboardById(dashboardId)
            .then((response) => response.data)
            .catch((error) => {
              // Ignore 404. We will redirect to AppDefaultDashboard
              if (!isAxiosError(error) || error.response?.status !== 404) {
                throw error
              }
              return null
            }),
      })

      to.meta.dashboard = dashboard
    },
  },
  {
    component: () => import('@js/pages/configuration/DashboardNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DashboardNew',
    path: '/configuration/dashboard/new',
  },
  {
    component: () => import('@js/pages/configuration/DashboardList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.dashboard.plural'),
      },
    },
    name: 'DashboardList',
    path: '/configuration/dashboard',
  },
] as Array<RouteRecordRaw>
