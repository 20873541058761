import Routing from '@js/Routing'
import axios from 'axios'

export function fetchMainMenu() {
  return axios.get(Routing.generate('u2_menu_mainjson'))
}

export function fetchUserMenu() {
  return axios.get(Routing.generate('u2_menu_userjson'))
}
