import { createQueryKeys } from '@lukemorales/query-key-factory'
import { authorizationItemApi } from '@js/api/authorizationItemApi'

export const authorizationItems = createQueryKeys('authorizationItems', {
  all: {
    queryKey: null,
    queryFn: () =>
      authorizationItemApi.fetchByQuery({ pagination: false }).then((response) => response.data),
  },
})
