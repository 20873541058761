import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import invariant from 'tiny-invariant'
import { currencyApi } from '@js/api/currencyApi'
import type { Currency } from '@js/model/currency'
import type { MaybeRef } from '@vueuse/core'

export const currencies = createQueryKeys('currencies', {
  all: {
    queryKey: null,
    queryFn: () => currencyApi.fetchAllCurrencies().then((response) => response.data),
  },
  single: (id: MaybeRef<Currency['id'] | undefined>) => ({
    queryKey: [id],
    queryFn: () => {
      const idValue = unref(id)
      invariant(idValue)
      return currencyApi.fetchCurrencyById(idValue).then((response) => response.data)
    },
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      currencyApi.fetchCurrenciesByQuery(unref(params)).then((response) => response.data),
  }),
})
