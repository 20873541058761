import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import invariant from 'tiny-invariant'
import * as UserApi from '@js/api/userApi'
import type { MaybeRef } from '@vueuse/core'
import type { User } from '@js/model/user'

export const users = createQueryKeys('users', {
  all: {
    queryKey: null,
    queryFn: () => UserApi.fetchAllUsers().then((response) => response.data),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      UserApi.fetchUsersByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRef<User['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = unref(id)
        invariant(idValue)
        return UserApi.fetchUserById(idValue).then((response) => response.data)
      },
      contextQueries: {
        userGroups: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserAssignedUserGroups(idValue).then((response) => response.data)
          },
        },
        roles: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserAssignedRoles(idValue).then((response) => response.data)
          },
        },
        units: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserAssignedUnits(idValue).then((response) => response.data)
          },
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserInheritedRoles(idValue).then((response) => response.data)
          },
        },
        inheritedUnits: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserInheritedUnits(idValue).then((response) => response.data)
          },
        },
        dashboards: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return UserApi.fetchUserAssignedDashboards(idValue).then((response) => response.data)
          },
        },
      },
    }
  },
})
