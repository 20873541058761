import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import { fetchLegalFormsByQuery } from '@js/api/legalFormApi'

export const legalForms = createQueryKeys('legalForms', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      fetchLegalFormsByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
})
