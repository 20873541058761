<script setup lang="ts">
import { AnimatePresence, Motion } from 'motion-v'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  VisuallyHidden,
} from 'reka-ui'
import { onMounted, ref, watch } from 'vue'
import AppLoader from '@js/components/loader/AppLoader.vue'
import SvgIcon from '@js/components/SvgIcon.vue'

withDefaults(
  defineProps<{
    loading?: boolean
    title?: string
    description?: string
  }>(),
  {
    loading: false,
    title: '',
    description: undefined,
  }
)

const isShowing = ref(false)
onMounted(() => {
  isShowing.value = true
})

watch(isShowing, (newValue) => {
  if (!newValue) {
    emit('close')
  }
})

const emit = defineEmits<(event: 'close') => void>()

function close() {
  emit('close')
}

// TODO: Add a save prompt and use default behaviour for closing dialog on click outside
// https://universalunits.atlassian.net/browse/UU-6291
// For now we prevent the dialog from closing on click outside, but we allow it to close if Escape is pressed.
// As soon as save prompt is implemented and used in the dialog we can allow closing on click outside and remove the
// code below
</script>

<template>
  <DialogRoot v-model:open="isShowing">
    <DialogPortal>
      <div class="relative">
        <AnimatePresence multiple>
          <Motion :initial="{ opacity: 0 }" :animate="{ opacity: 1 }" :exit="{ opacity: 0 }">
            <DialogOverlay
              class="data-[state=open]:animate-overlayShow fixed inset-0 bg-gray-900/80"
            />
          </Motion>
          <!-- MODAL -->
          <Motion :initial="{ opacity: 0 }" :animate="{ opacity: 1 }" :exit="{ opacity: 0 }">
            <DialogContent
              class="data-[state=open]:animate-contentShow app-dialog fixed left-1/2 top-[10vh] mx-auto my-0 flex max-h-3/4 flex-auto -translate-x-1/2 transform flex-col rounded bg-white p-0 leading-loose shadow"
              @interact-outside="(event) => event.preventDefault()"
            >
              <!-- HEADER -->
              <DialogTitle
                class="flex h-9 shrink-0 items-center justify-between rounded rounded-b-none bg-gray-950 bg-gradient-to-b from-gray-700 px-3 py-0"
              >
                <span
                  class="truncate pr-6 text-base font-bold uppercase tracking-wider text-white hover:no-underline focus:no-underline"
                >
                  {{ title }}
                  <VisuallyHidden>
                    <DialogDescription> {{ description ?? title }}</DialogDescription>
                  </VisuallyHidden>
                </span>

                <DialogClose as-child>
                  <button type="button" class="flex items-center" @click="close">
                    <SvgIcon icon="cross" class="text-white" />
                  </button>
                </DialogClose>
              </DialogTitle>

              <!-- LOADER -->
              <div v-if="loading" class="flex h-32 items-center justify-center">
                <AppLoader class="flex-grow" size="large" />
              </div>

              <!-- BODY -->
              <div v-else class="h-full overflow-scroll p-3">
                <slot />
              </div>

              <!-- FOOTER -->
              <div
                v-if="!!$slots.buttons"
                class="flex h-12 items-center justify-end gap-1 rounded rounded-t-none border-t border-gray-300 bg-gray-100 p-2"
              >
                <slot v-if="!loading" name="buttons" />
              </div>
            </DialogContent>
          </Motion>
        </AnimatePresence>
      </div>
    </DialogPortal>
  </DialogRoot>
</template>
