import axios from 'axios'
import type { Status } from '@js/model/status'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { WorkflowBinding } from '@js/api/workflowApi'

const basePath = '/api/workflow-bindings'
type WorkflowBindingCollectionQuery = ApiPaginationQueryParams & {
  'sort[workflow.name]'?: SortingDirection
  search?: string | Array<string>
}
export type StatusMapping = {
  source: Status['@id']
  destination: Status['@id']
}
type WorkflowBindingPatchPayload = {
  binding: WorkflowBinding['@id']
  targetWorkflow: WorkflowBinding['workflow']
  statusMappings: Array<StatusMapping>
}

export function fetchWorkflowBindingsByQuery(query: WorkflowBindingCollectionQuery) {
  return axios.get<HydraCollectionResponse<WorkflowBinding>>(basePath, {
    params: { ...query },
  })
}

export function fetchWorkflowBindingById(bindingId: WorkflowBinding['bindingId']) {
  return fetchWorkflowBindingByIri(`${basePath}/${bindingId}`)
}

export function fetchWorkflowBindingByIri(iri: WorkflowBinding['@id']) {
  return axios.get<WorkflowBinding>(iri)
}

export function updateWorkflowBinding(payload: WorkflowBindingPatchPayload) {
  return axios.patch<WorkflowBinding>(`${payload.binding}`, payload)
}

export const workflowBindingApi = {
  fetchWorkflowBindingById,
  fetchWorkflowBindingByIri,
  fetchWorkflowBindingsByQuery,
  updateWorkflowBinding,
}
