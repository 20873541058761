import axios from 'axios'
import type { ApiResource, HydraCollectionResponse } from '@js/types'

export const basePath = '/api/workflow/transition/condition-types'

export type ConditionType = ApiResource & {
  id: string
  description: string
  help: string
  name: string
}

export function fetchAllTaskTransitionConditionTypes() {
  return axios.get<HydraCollectionResponse<ConditionType>>(basePath, {
    params: { pagination: false },
  })
}

export function fetchTaskTransitionConditionTypeByIri(iri: ConditionType['@id']) {
  return axios.get<ConditionType>(iri)
}

export function fetchTaskTransitionConditionTypeById(id: ConditionType['id']) {
  return fetchTaskTransitionConditionTypeByIri(`${basePath}/${id}`)
}

export const transitionConditionTypeApi = {
  fetchAllTaskTransitionConditionTypes,
  fetchTaskTransitionConditionTypeById,
  fetchTaskTransitionConditionTypeByIri,
}
