import axios from 'axios'
import type { Datasheet, Field, LayoutItem } from '@js/model/datasheet'
import type { HydraCollectionResponse } from '@js/types'

export const basePath = '/api/layouts/{layoutId}/fields'
const buildBasePath = (layoutId: Datasheet['id']) =>
  basePath.replace('{layoutId}', layoutId.toString())

export function fetchDatasheetFieldByIri(iri: Field['@id']) {
  return axios.get<Field>(iri)
}

export function fetchDatasheetFieldById(id: Field['id'], layoutId: Datasheet['id']) {
  return fetchDatasheetFieldByIri(`${buildBasePath(layoutId)}/${id}`)
}

export function fetchAllDatasheetFields(layoutId: Datasheet['id']) {
  return axios.get<HydraCollectionResponse<Field>>(buildBasePath(layoutId), {
    params: { pagination: false },
  })
}

export function saveDatasheetField(
  field: Partial<Omit<Field, 'item'>> & { item?: LayoutItem['@id'] },
  layoutId: Datasheet['id']
) {
  if (field.id === undefined) {
    return axios.post<Field>(`${buildBasePath(layoutId)}`, field)
  }
  return axios.put<Field>(`${buildBasePath(layoutId)}/${field.id}`, field)
}

export function deleteDatasheetField(fieldId: Field['id'], layoutId: Datasheet['id']) {
  return axios.delete<Field>(`${buildBasePath(layoutId)}/${fieldId}`)
}

export const datasheetFieldApi = {
  fetchDatasheetFieldByIri,
  fetchDatasheetFieldById,
  fetchAllDatasheetFields,
  saveDatasheetField,
  buildBasePath,
  deleteDatasheetField,
}
