<script setup lang="ts">
import { tv } from 'tailwind-variants'
import ButtonBase from '@js/components/buttons/ButtonBase.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import type { Color, Icon } from '@js/utilities/name-lists'

const props = withDefaults(
  defineProps<{
    buttonStyle?: 'outlined' | 'solid' | 'text'
    color?: Color
    disabled?: boolean
    grouped?: boolean
    icon?: Icon
    to?: string | object
    tooltip?: string
    type?: 'button' | 'reset' | 'submit'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    class?: any
  }>(),
  {
    buttonStyle: 'text',
    color: 'action',
    disabled: false,
    grouped: false,
    icon: undefined,
    to: undefined,
    tooltip: undefined,
    type: 'button',
    class: undefined,
  }
)

defineSlots<{
  default?: (props: Record<string, never>) => unknown
  icon?: (props: Record<string, never>) => unknown
}>()

const emit = defineEmits<(event: 'click', payload: Event) => void>()

const button = tv({
  base: 'button inline-flex items-center justify-center gap-1 border bg-transparent px-2 py-1.5 leading-4 text-action no-underline transition-colors duration-300 ease-in-out hover:bg-action-transparent hover:text-action-darker hover:no-underline focus:bg-action-transparent focus:text-action-darker focus:no-underline active:bg-blue-100',
  variants: {
    style: {
      solid: 'border-transparent shadow',
      outlined: 'shadow',
      text: 'border-transparent',
    },
    color: {
      action:
        'text-action hover:bg-action-transparent hover:text-action-darker focus:bg-action-transparent focus:text-action-darker',
      alert:
        'text-alert hover:bg-alert-transparent hover:text-alert-darker focus:bg-alert-transparent focus:text-alert-darker',
      bad: 'text-bad hover:bg-bad-transparent hover:text-bad-darker focus:bg-bad-transparent focus:text-bad-darker',
      black:
        'text-off-black hover:bg-transparent-black hover:text-gray-800 focus:bg-transparent-black focus:text-gray-800',
      good: 'text-good hover:bg-good-transparent hover:text-good-darker focus:bg-good-transparent focus:text-good-darker',
      invert:
        'text-gray-700 hover:bg-transparent-black hover:text-gray-800 focus:bg-transparent-black focus:text-gray-800',
      'light-blue': '',
      u2: 'text-u2 hover:bg-u2-transparent hover:text-u2-darker focus:bg-u2-transparent focus:text-u2-darker',
      'u2-inverse':
        'text-u2-inverse hover:bg-u2-inverse-transparent hover:text-u2-inverse-darker focus:bg-u2-inverse-transparent focus:text-u2-inverse-darker',
      white:
        'text-white hover:bg-transparent-black hover:text-white focus:bg-transparent-black focus:text-white',
    },
    grouped: {
      true: 'mx-0 bg-blue-100 first:rounded-l last:rounded-r',
      false: 'rounded',
    },
    disabled: {
      true: 'cursor-not-allowed',
    },
  },
  compoundVariants: [
    {
      style: 'solid',
      color: 'action',
      class:
        'bg-action text-white hover:bg-blue-700 hover:text-white focus:bg-blue-700 focus:text-white active:bg-blue-500',
    },
    {
      style: 'solid',
      color: 'alert',
      class:
        'bg-alert text-white hover:bg-alert-darker hover:text-white focus:bg-alert-darker focus:text-white',
    },
    {
      style: 'solid',
      color: 'bad',
      class:
        'bg-bad text-white hover:bg-bad-darker hover:text-white focus:bg-bad-darker focus:text-white',
    },
    {
      style: 'solid',
      color: 'black',
      class:
        'bg-off-black text-white hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white',
    },
    {
      style: 'solid',
      color: 'good',
      class:
        'bg-good text-white hover:bg-good-darker hover:text-white focus:bg-good-darker focus:text-white active:bg-good-lighter',
    },
    {
      style: 'solid',
      color: 'invert',
      class:
        'bg-gray-700 text-white hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white active:bg-gray-700',
    },
    {
      style: 'solid',
      color: 'light-blue',
      class:
        'bg-blue-100 text-action hover:bg-action-transparent hover:text-action focus:bg-action-transparent focus:text-action',
    },
    {
      style: 'solid',
      color: 'u2',
      class:
        'bg-u2 text-white hover:bg-u2-darker hover:text-white focus:bg-u2-darker focus:text-white',
    },
    {
      style: 'solid',
      color: 'u2-inverse',
      class:
        'bg-u2-inverse text-white hover:bg-u2-inverse-darker hover:text-white focus:bg-u2-inverse-darker focus:text-white',
    },
    {
      style: 'solid',
      color: 'white',
      class:
        'bg-white text-off-black hover:bg-gray-100 hover:text-off-black focus:bg-gray-100 focus:text-off-black',
    },

    {
      style: 'outlined',
      color: 'action',
      class:
        'border-action text-action hover:border-action-darker hover:bg-action-transparent hover:text-action-darker focus:border-action-darker focus:bg-action-transparent focus:text-action-darker',
    },
    {
      style: 'outlined',
      color: 'alert',
      class:
        'border-alert text-alert hover:border-alert-darker hover:bg-alert-transparent hover:text-alert-darker focus:border-alert-darker focus:bg-alert-transparent focus:text-alert-darker',
    },
    {
      style: 'outlined',
      color: 'bad',
      class:
        'border-bad text-bad hover:border-bad-darker hover:bg-bad-transparent hover:text-bad-darker focus:border-bad-darker focus:bg-bad-transparent focus:text-bad-darker',
    },
    {
      style: 'outlined',
      color: 'black',
      class:
        'border-off-black text-off-black hover:border-gray-800 hover:bg-transparent-black hover:text-gray-800 focus:border-gray-800 focus:bg-transparent-black focus:text-gray-800',
    },
    {
      style: 'outlined',
      color: 'good',
      class:
        'border-good text-good hover:border-good-lighter hover:bg-good-transparent hover:text-good-lighter focus:border-good-lighter focus:bg-good-transparent focus:text-good-lighter',
    },
    {
      style: 'outlined',
      color: 'invert',
      class:
        'border-gray-600 text-gray-600 hover:border-gray-800 hover:bg-transparent-black hover:text-gray-800 focus:border-gray-800 focus:bg-transparent-black focus:text-gray-800',
    },
    {
      style: 'outlined',
      color: 'light-blue',
      class:
        'border-action-darker text-action hover:border-action-darker hover:bg-action-transparent hover:text-action-darker focus:border-action-darker focus:bg-action-transparent focus:text-action-darker',
    },
    {
      style: 'outlined',
      color: 'u2',
      class:
        'border-u2 text-u2 hover:border-u2-darker hover:bg-u2-transparent hover:text-u2-darker focus:border-u2-darker focus:bg-u2-transparent focus:text-u2-darker',
    },
    {
      style: 'outlined',
      color: 'u2-inverse',
      class:
        'border-u2-inverse text-u2-inverse hover:border-u2-inverse-darker hover:bg-u2-inverse-transparent hover:text-u2-inverse-darker focus:border-u2-inverse-darker focus:bg-u2-inverse-transparent focus:text-u2-inverse-darker',
    },
    {
      style: 'text',
      grouped: true,
      class: 'border-l-blue-200 first:border-l-transparent',
    },
    {
      style: 'outlined',
      grouped: true,
      class: '[&:not(:first-child)]:border-l-0',
    },
    {
      style: ['text', 'solid', 'outlined'],
      disabled: true,
      class: 'text-gray-500 hover:text-gray-500 focus:text-gray-500',
    },
    {
      style: ['text', 'outlined'],
      disabled: true,
      class: 'bg-transparent hover:bg-transparent focus:bg-transparent',
    },
    {
      style: ['text'],
      disabled: true,
      grouped: true,
      class: 'border-l-gray-200 bg-gray-100 hover:bg-gray-100 focus:bg-gray-100',
    },
    {
      style: 'solid',
      disabled: true,
      class: 'bg-gray-200 hover:bg-gray-200 focus:bg-gray-200',
    },
    {
      style: 'outlined',
      disabled: true,
      class: 'border-gray-300 hover:border-gray-300 focus:border-gray-300',
    },
  ],
  defaultVariants: {
    color: 'action',
  },
})
</script>

<template>
  <ButtonBase
    :disabled="disabled"
    :to="to"
    :tooltip="tooltip"
    :type="type"
    :class="[
      button({ class: props.class, style: buttonStyle, color: color, grouped, disabled }),
      buttonStyle === 'solid' && 'colored-button',
      buttonStyle === 'outlined' && 'outlined-button',
      buttonStyle === 'text' && 'text-button',
    ]"
    @click="emit('click', $event)"
  >
    <slot name="icon">
      <SvgIcon v-if="icon" :icon="icon" />
    </slot>

    <slot name="default" />
  </ButtonBase>
</template>
