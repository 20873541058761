import axios from 'axios'
import type { Datasheet, ItemUnitValue, LayoutItem } from '@js/model/datasheet'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { Period } from '@js/model/period'
import type { Unit } from '@js/model/unit'

const basePath = '/api/item-values'

export type ItemUnitValueCollectionQuery = ApiPaginationQueryParams & {
  search?: string | Array<string>
  'datasheet.id'?: Datasheet['id']
  'item.id'?: LayoutItem['id'] | Array<LayoutItem['id']>
  'item.refId'?: LayoutItem['refId'] | Array<LayoutItem['refId']>
  'unit.id'?: Unit['id'] | Array<Unit['id']>
  'unit.refId'?: Unit['refId'] | Array<Unit['refId']>
  'period.id'?: Period['id'] | Array<Period['id']>
  'period.name'?: Period['name'] | Array<Period['name']>
}

export function fetchItemUnitValueById(id: ItemUnitValue['id']) {
  return axios.get<ItemUnitValue>(`${basePath}/${id}`)
}

export function fetchItemUnitValuesByQuery(query?: ItemUnitValueCollectionQuery) {
  return axios.get<HydraCollectionResponse<ItemUnitValue>>(basePath, {
    params: { ...query },
  })
}

export const itemUnitValueApi = {
  fetchItemUnitValueById,
  fetchItemUnitValuesByQuery,
  basePath,
}
