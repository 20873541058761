import { StatusCodes } from 'http-status-codes'
import { useAuthStore } from '@js/stores/auth'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import { createErrorLocation } from '@js/router/helpers'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/user/UserList.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
      layoutFormat: 'wide',
      globalSearch: {
        icon: 'users',
        name: () => Translator.trans('u2_core.users'),
      },
    },
    name: 'UserList',
    path: '/user',
  },
  {
    component: () => import('@js/pages/user/UserEdit.vue'),
    meta: {
      layout: BaseLayout,
    },
    name: 'UserEdit',
    path: '/user/:id/edit',
    beforeEnter: async (to: RouteLocation) => {
      const authStore = useAuthStore()
      if (authStore.hasRole('ROLE_USER_GROUP_ADMIN') || authStore.user?.id === +to.params.id) {
        return
      }
      return createErrorLocation(to, StatusCodes.FORBIDDEN)
    },
  },
  {
    component: () => import('@js/pages/user/UserNew.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'UserNew',
    path: '/user/new',
  },
  {
    component: () => import('@js/pages/user/UserSettingsEdit.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.edit_user_settings'),
      },
    },
    name: 'UserSettingsEdit',
    path: '/user-setting/edit',
  },
] as Array<RouteRecordRaw>
