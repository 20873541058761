import axios from 'axios'
import invariant from 'tiny-invariant'
import type { FileEntity } from '@js/model/file'
import type { Attachment, UnrestrictedAttachment } from '@js/model/attachment'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'

const basePath = '/attachments'
export function fetchAllAttachments(iri: ApiResourceId) {
  return axios.get<HydraCollectionResponse<Attachment>>(iri + basePath)
}

export function downloadAttachment(attachment: UnrestrictedAttachment) {
  return axios.get<Blob>(attachment.links.downloadPath, { responseType: 'blob' })
}

export function deleteAttachment(attachment: UnrestrictedAttachment) {
  invariant(attachment.links.unattachPath)
  return axios.delete(attachment.links.unattachPath)
}

export function linkAttachment(linkData: { resourceIri: ApiResourceId; file: FileEntity['@id'] }) {
  return axios.post(linkData.resourceIri + basePath, {
    file: linkData.file,
  })
}

export const attachmentApi = {
  deleteAttachment,
  downloadAttachment,
  fetchAllAttachments,
  linkAttachment,
}
