import axios from 'axios'
import { choiceTypesToResourceCollectionEndpoint } from '@js/model/choice'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { Entries } from 'type-fest'
import type { Choice, ChoiceCollectionEndpoint } from '@js/model/choice'

export const resourceCollectionEndpointToChoiceTypeMap = new Map(
  (
    Object.entries(choiceTypesToResourceCollectionEndpoint) as Entries<
      typeof choiceTypesToResourceCollectionEndpoint
    >
  ).map(([key, value]) => [value, key])
)
type ChoiceCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[enabled]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchChoiceByQuery(
  collectionPath: ChoiceCollectionEndpoint,
  query?: ChoiceCollectionQuery
) {
  return axios.get<HydraCollectionResponse<Choice>>(collectionPath, {
    params: { ...query },
  })
}

export function createChoice(choice: Pick<Choice, '@type' | 'name' | 'enabled' | 'rules'>) {
  return axios.post<Choice>(choiceTypesToResourceCollectionEndpoint[choice['@type']], choice)
}

export function updateChoice(choice: Choice) {
  return axios.put<Choice>(choice['@id'], choice)
}

export function deleteChoice(choice: Choice) {
  return axios.delete(choice['@id'])
}

export const choiceApi = {
  createChoice,
  deleteChoice,
  fetchChoiceByQuery,
  updateChoice,
}
