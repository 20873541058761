import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { Country } from '@js/model/country'

export const countryApiBasePath = '/api/countries'

export function fetchAllCountries() {
  return axios.get<HydraCollectionResponse<Country>>(countryApiBasePath, {
    params: { pagination: false },
  })
}

export function fetchCountryById(id: number) {
  return fetchCountryByIri(`${countryApiBasePath}/${id}`)
}

export function fetchCountryByIri(iri: ApiResourceId) {
  return axios.get<Country>(iri)
}

export function deleteCountryById(id: number) {
  return axios.delete<Country>(`${countryApiBasePath}/${id}`)
}

export function saveCountry(country: Partial<Country>) {
  if (country.id === undefined) {
    return axios.post<Country>(countryApiBasePath, country)
  }
  return axios.put<Country>(`${countryApiBasePath}/${country.id}`, country)
}

export type CountryCollectionQuery = ApiPaginationQueryParams & {
  'sort[iso3166code]'?: SortingDirection
  'sort[nameShort]'?: SortingDirection
  'sort[nameLong]'?: SortingDirection
  'sort[nationalityShort]'?: SortingDirection
  search?: string | Array<string>
}
export function fetchCountriesByQuery(query: CountryCollectionQuery) {
  return axios.get<HydraCollectionResponse<Country>>(countryApiBasePath, {
    params: { ...query },
  })
}
