import { fetchFilesByQuery } from '@js/api/fileApi'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'

export const files = createQueryKeys('files', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      fetchFilesByQuery({
        ...unref(filters),
      }),
  }),
})
