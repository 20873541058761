import { fetchUserSettings } from '@js/api/userApi'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { syncRef, useLocalStorage } from '@vueuse/core'
import { useAuthStore } from '@js/stores/auth'
import { queries } from '@js/query'
import queryClient from '@js/queryClient'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const locales = ['en', 'de'] as const

export const localeStorageKey = 'locale'
export const localeFallback = 'en'

export type Locale = (typeof locales)[number]

export const useUserSettingsStore = defineStore('user-settings', () => {
  // Initialise the value from the local storage
  const defaultLocale = useLocalStorage<Locale>(localeStorageKey, localeFallback)

  // Use an own instance for the current session, so that changes to the local storage don't affect the current session
  const locale = ref<Locale>(defaultLocale.value)
  syncRef(locale, defaultLocale, { direction: 'ltr' })

  watch(locale, () => {
    queryClient.invalidateQueries({ queryKey: queries.menu.mainMenuJson.queryKey })
    queryClient.invalidateQueries({ queryKey: queries.menu.userMenuJson.queryKey })
  })

  const isLocaleInSync = computed(() => locale.value === defaultLocale.value)
  function syncLocale() {
    if (!isLocaleInSync.value) {
      locale.value = defaultLocale.value
    }
  }

  const authStore = useAuthStore()
  function refreshUserSettings() {
    if (authStore.user) {
      fetchUserSettings(authStore.user['id']).then((response) => {
        locale.value = response.data.applicationLanguage
      })
    }
  }

  return {
    isLocaleInSync,
    locale,
    refreshUserSettings,
    syncLocale,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSettingsStore, import.meta.hot))
}
