<script setup lang="ts">
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    timer?: number
    size?: 'small' | 'medium' | 'large'
  }>(),
  {
    timer: 0,
    size: 'large',
  }
)
const renderContent = ref(true)
if (props.timer) {
  renderContent.value = false
  setTimeout(() => {
    renderContent.value = true
  }, props.timer)
}
</script>

<template>
  <div class="flex items-center justify-center">
    <svg
      v-if="renderContent"
      alt="loading-spinner"
      class="loader-is-showing m-auto animate-spin object-none text-gray-500"
      :class="{
        'size-4': size === 'small',
        'size-10': size === 'medium',
        'size-16': size === 'large',
      }"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
</template>
