import { createQueryKeys } from '@lukemorales/query-key-factory'
import * as SavedFilterSubscriptionApi from '@js/api/savedFilterSubscriptionApi'

export const savedFilterSubscriptions = createQueryKeys('savedFilterSubscriptions', {
  all: null,
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            SavedFilterSubscriptionApi.fetchSavedFilterSubscriptionAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            SavedFilterSubscriptionApi.fetchSavedFilterSubscriptionInheritedAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            SavedFilterSubscriptionApi.fetchSavedFilterSubscriptionAssignedUserGroups(id).then(
              (response) => response.data
            ),
        },
      },
    }
  },
})
