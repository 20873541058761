import { createQueryKeys } from '@lukemorales/query-key-factory'
import * as SavedFilterApi from '@js/api/savedFilterApi'

export const savedFilters = createQueryKeys('savedFilters', {
  all: { queryKey: null, queryFn: () => SavedFilterApi.fetchAllSavedFilters() },
  favouriteSavedFilters: {
    queryKey: null,
    queryFn: () => SavedFilterApi.fetchSavedFilterMyFavourites(),
  },
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            SavedFilterApi.fetchSavedFilterAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            SavedFilterApi.fetchSavedFilterInheritedAssignedUsers(id).then(
              (response) => response.data
            ),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            SavedFilterApi.fetchSavedFilterAssignedUserGroups(id).then((response) => response.data),
        },
        subscriptions: {
          queryKey: null,
          queryFn: () =>
            SavedFilterApi.fetchSavedFilterSubscriptions(id).then((response) => response.data),
        },
      },
    }
  },
})
