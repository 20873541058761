import axios from 'axios'
import Routing from '@js/Routing'

export function login(payload: { username: string; password: string; rememberMe: boolean }) {
  return axios.post('/api/login', {
    username: payload.username,
    password: payload.password,
    _remember_me: payload.rememberMe,
  })
}

export function logout() {
  return axios.get('/api/logout')
}

export function confirmTwoFactor(payload: { authCode: string; trustDevice: boolean }) {
  return axios.post(Routing.generate('two_factor_authentication_login_check'), {
    _auth_code: payload.authCode,
    _trusted: payload.trustDevice,
  })
}

export function refreshJwt() {
  return axios.get(Routing.generate('u2_refreshjwt__invoke'))
}

export const authApi = {
  login,
  logout,
  confirmTwoFactor,
  refreshJwt,
}
