import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { exchangeRateApi } from '@js/api/exchangeRateApi'
import type { ExchangeRate } from '@js/model/exchangeRate'
import type { MaybeRef } from '@vueuse/core'

export const exchangeRates = createQueryKeys('exchangeRates', {
  single: (id: MaybeRef<ExchangeRate['id']>) => ({
    queryKey: [id],
    queryFn: () =>
      exchangeRateApi.fetchExchangeRateById(unref(id)).then((response) => response.data),
  }),
})
