import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { itemApi } from '@js/api/itemApi'
import invariant from 'tiny-invariant'
import type { MaybeRef } from '@vueuse/core'
import type { LayoutItem } from '@js/model/datasheet'

export const items = createQueryKeys('items', {
  all: {
    queryKey: null,
    queryFn: () => itemApi.fetchAllDatasheetItems().then((response) => response.data),
  },
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      itemApi.fetchDatasheetItemsByQuery(unref(params)).then((response) => response.data),
  }),
  single: (id: MaybeRef<LayoutItem['id'] | undefined>) => ({
    queryKey: [id],
    queryFn: () => {
      const unrefId = unref(id)
      invariant(unrefId, 'Expected id')
      return itemApi.fetchDatasheetItemById(unrefId).then((response) => response.data)
    },
  }),
})
