import { createQueryKeys } from '@lukemorales/query-key-factory'
import * as MenuApi from '@js/api/menuApi'

export const menu = createQueryKeys('menu', {
  mainMenuJson: {
    queryKey: null,
    queryFn: () => MenuApi.fetchMainMenu(),
  },
  userMenuJson: {
    queryKey: null,
    queryFn: () => MenuApi.fetchUserMenu(),
  },
})
